import { ContentPropertiesSchema } from "content/contentSchemas";
import z from "zod";

const TagsSchema = z.object({
    pageName: z.array(z.string()).optional(),
    state: z.array(z.string()).optional(),
    applicationMode: z.array(z.string()).optional(),
    clubCode: z.array(z.string().regex(/^\d{3}$/)).optional(),
});
export type Tags = z.infer<typeof TagsSchema>;

export const ContentMetadataSchema = z.object({
    _description: z.string().nonempty().startsWith("content "),
    _tags: TagsSchema,
});

const LooseContentMetadataSchema = z.object({
    _description: z.string().nonempty().startsWith("content "),
    _tags: z.record(z.array(z.string()).optional()),
});

export type LooseContentMetadata = z.infer<typeof LooseContentMetadataSchema>;

export type ContentProperties = z.infer<typeof ContentPropertiesSchema>;
export type ContentMetadata = z.infer<typeof ContentMetadataSchema>;
export type Content = ContentProperties & ContentMetadata;
export type ContentTarget = Partial<ContentProperties>;

export const ContentArraySchema = z.array(ContentPropertiesSchema.partial().and(ContentMetadataSchema));

export function filterContent<T extends LooseContentMetadata>(
    tags: Map<string, string>,
    contentObject: T[]
): Omit<T, "_description" | "_tags"> {
    const targetEntries = contentObject
        .filter((item) => matchTag(tags, item._tags))
        .flatMap((item) => Object.entries(item).filter(([key, value]) => !key.startsWith("_")));

    return Object.fromEntries(targetEntries) as Omit<T, "_description" | "_tags">;
}

export function matchTag(searchTags: Map<string, string>, contentTags: Tags) {
    const entries = Object.entries(contentTags);
    return (
        entries.length === 0 ||
        entries
            .map(([key, value]) => {
                const searchTagValue = searchTags.get(key);
                return searchTagValue && value.includes(searchTagValue);
            })
            .reduce((previousValue, currentValue) => previousValue && currentValue)
    );
}
