/**
 * calculates the duration between two dates.
 * @param {number} differenceInSeconds the amount of remaining seconds.
 * @return {string} a string of the duration with format 'm:ss'
 */
export const formatDuration = (differenceInSeconds) => {
    if (!differenceInSeconds) {
        return "0:00";
    } // divide by 0 protection
    const minutes = Math.abs(Math.floor(differenceInSeconds / 60)).toString();
    const seconds = Math.abs(Math.floor(differenceInSeconds) % 60).toString();
    return `${minutes}:${seconds.length < 2 ? 0 + seconds : seconds}`;
};
