import { z } from "zod";

export const DTOPageEnum = z.enum([
    "welcome",
    "quoteStart",
    "quoteResults",
    "personalInfo",
    "invalid-link",
    "beneficiary",
    "secondaryAddressee",
    "healthStatement",
    "paymentInfo",
    "review",
    "consent",
    "processing",
    "approved",
    "declined",
    "unableToFinish",
    "oopsSorry",
    "sessionExpired",
    "checkBack",
    "welcomeBack",
]);

export type DTOPages = z.infer<typeof DTOPageEnum>;

export const PagesEnumSchema = z.enum([
    "addressee",
    "approved",
    "beneficiaries",
    "check-back",
    "consent",
    "declined",
    "health-statement",
    "oops-sorry",
    "invalid-link",
    "payment-info",
    "personal-info",
    "processing",
    "quote-results",
    "quote-start",
    "review",
    "session-expired",
    "unable-to-finish",
    "welcome-back",
    "welcome",
]);

export type Pages = z.infer<typeof PagesEnumSchema>;

export const PathEnumSchema = z.enum([
    "/", // Welcome page
    "/welcome-back",
    "/check-back",
    "/system-error",
    "/session-expired",
    "/invalid-link",
    "/quote",
    "/quote/results",
    "/app/personal-info",
    "/app/beneficiaries",
    "/app/secondary-addressee",
    "/app/health-statement",
    "/app/payment-info",
    "/app/review",
    "/app/consent",
    "/app/processing",
    "/results/approved",
    "/results/declined",
    "/results/unable-to-finish",
]);

export type Paths = z.infer<typeof PathEnumSchema>;

const pathToPageNameMapping: { [K in Paths]: Pages } = {
    "/": "welcome",
    "/welcome-back": "welcome-back",
    "/check-back": "check-back",
    "/system-error": "oops-sorry",
    "/invalid-link": "invalid-link",
    "/session-expired": "session-expired",
    "/quote": "quote-start",
    "/quote/results": "quote-results",
    "/app/personal-info": "personal-info",
    "/app/beneficiaries": "beneficiaries",
    "/app/secondary-addressee": "addressee",
    "/app/health-statement": "health-statement",
    "/app/payment-info": "payment-info",
    "/app/review": "review",
    "/app/consent": "consent",
    "/app/processing": "processing",
    "/results/approved": "approved",
    "/results/declined": "declined",
    "/results/unable-to-finish": "unable-to-finish",
};

export function toPageNameFromPathname(pathname: string): Pages | undefined {
    // Prepend forward slash to relative pathname values which are missing it
    // as it turns out that useNavigate's navigate method causes this
    if (pathname && pathname.length > 0 && pathname[0] !== "/") {
        pathname = "/" + pathname;
    }

    const parseResult = PathEnumSchema.safeParse(pathname);

    if (!parseResult.success) {
        console.warn(parseResult.error);
        return undefined;
    }

    const path = parseResult.data;

    return pathToPageNameMapping[path];
}

export function isFinalPage(pathname: Paths) {
    switch (toPageNameFromPathname(pathname)) {
        case "approved":
        case "declined":
        case "unable-to-finish":
        case "session-expired":
            return true;
        default:
            return false;
    }
}
