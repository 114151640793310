import ThemeProvider from "./ThemeProvider";
import IdleTimerProvider from "./IdleTimerProvider";
import FormStateContextProvider from "./FormStateContextProvider";

const Providers = ({ pathname = "", children }) => (
    <ThemeProvider>
        <FormStateContextProvider pathname={pathname}>
            <IdleTimerProvider>{children}</IdleTimerProvider>
        </FormStateContextProvider>
    </ThemeProvider>
);

export default Providers;
