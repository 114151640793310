import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SplitColumn, PageDetails, ButtonGroup, InfoPanel } from "layout";
import { usePageTitle, useFormState, useSendInteraction } from "hooks";
import Button from "components/inputs/button/Button";
import { H1, H2 } from "components/text";
import BeneficiariesForm, { BeneficiariesFormFields, OnSubmitArgs } from "features/forms/beneficiaries-form/BeneficiariesForm";
import { setRawHtml } from "content/setRawHtml";
import RELATIONSHIP_SELECT_OPTIONS from "constants/relationshipSelectOptions";
import { useEffect } from "react";
import { emptyToUndefined } from "utils/conversions";
import { ApplicationModeEnumSchema, makeBaseApplicationSchema } from "state/ApplicationStateSchema";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { BeneficiariesContentSchema } from "content/contentSchemas";
import { SubmitHandler } from "react-hook-form";

const relationships = RELATIONSHIP_SELECT_OPTIONS.map((option) => option.value);
const ApplicationStep3Schema = z.object({
    beneficiary: z
        .object({
            firstName: z.string().nullish(),
            middleInitial: z.string().nullish(),
            lastName: z.string().nullish(),
            relationship: z.enum([relationships[0], ...relationships.slice(1)]).nullish(),
        })
        .nullish(),
});
type ApplicationStep3 = z.infer<typeof ApplicationStep3Schema>;

const BeneficiaryState = makeBaseApplicationSchema(
    ApplicationStep3Schema,
    z.object({
        applicationMode: ApplicationModeEnumSchema,
    })
);

const pageName = DTOPageEnum.enum.beneficiary;

const Beneficiaries = () => {
    usePageTitle("Beneficiaries | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);
    const navigate = useNavigate();
    const parseResult = BeneficiaryState.safeParse(state);

    useEffect(() => {
        if (!parseResult.success) {
            console.error(parseResult.error);
            navigate("/system-error");
        }
    });

    const { result: content } = useContent({
        targetSchema: BeneficiariesContentSchema,
        applicationMode: state.applicationMode,
        pageName: "beneficiaries",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state,
    });

    if (!parseResult.success) {
        return null;
    }

    const applicationData: ApplicationStep3 = parseResult.data.application;

    const handleClickBack = () => {
        navigate("/app/personal-info");
    };

    const handleClickNext: SubmitHandler<BeneficiariesFormFields> = (submittedData: OnSubmitArgs) => {
        dispatch({
            type: "BENEFICIARY_SELECTED",
            firstName: submittedData.firstName as string,
            middleInitial: emptyToUndefined(submittedData?.middleInitial?.toUpperCase() ?? ""),
            lastName: submittedData.lastName as string,
            relationship: submittedData.relationship as string,
            percentage: parseInt(submittedData?.percentage?.value ?? "100", 10), // HACK: Component exposes value as complex object
        });

        navigate("/app/secondary-addressee");
    };

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <BeneficiariesForm
                            onSubmit={handleClickNext}
                            content={content}
                            initialValues={{
                                firstName: applicationData?.beneficiary?.firstName ?? undefined,
                                middleInitial: applicationData?.beneficiary?.middleInitial ?? undefined,
                                lastName: applicationData?.beneficiary?.lastName ?? undefined,
                                relationship: applicationData?.beneficiary?.relationship ?? undefined,
                            }}
                        />
                        <ButtonGroup>
                            <Button label={content.previousLabel} onClick={handleClickBack} />
                            <Button label={content.nextLabel} type="submit" color="primary" form="beneficiaries-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default Beneficiaries;
