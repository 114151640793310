import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { Controller, FieldErrors, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import Popover from "components/popover/Popover";
import { InputText, RadioGroup, ErrorMessage } from "components/inputs";
import { FieldError } from "components/FieldError";
import { noSpecialCharactersRegex, digitOnlyRegex } from "constants/validations";
import { setRawHtml } from "content/setRawHtml";
import STATE_SELECT_OPTIONS from "constants/stateSelectOptions";
import SelectNative from "components/inputs/select/SelectNative";
import { isPhoneFormatValid } from "utils/phone-format-validation";
import { InputPhoneControlled } from "components/inputs/phone/InputPhone";
import { format10DigitNanpaPhone, parseFormatted10DigitPhone } from "utils/phone";
import { toLowerCaseYesNoFromBoolean } from "utils/conversions";
import { logErrors } from "utils/logErrors";
import { InputZipCode } from "components/inputs/zip-code/InputZipCode";
import { AddresseeContent } from "content/contentSchemas";
import { ChangeEvent } from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const NarrowInputGroup = styled(InputGroup)`
    width: 250px;
`;

const Label = styled.label`
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    font-weight: bold;
`;

const InputHelpText = styled.p`
    margin: 0;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    color: ${(props) => props.theme.colors.grey7};

    @media ${devices.minTablet} {
        margin-left: ${(props) => props.theme.spacing.padding.xs};
    }
`;

const Legend = styled.legend`
    display: flex;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
`;

const SplitContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media ${devices.tablet} {
        flex-direction: column;
    }
`;

const SpaceBetweenSplitContainer = styled(SplitContainer)`
    justify-content: space-between;
`;

export type AddresseeFields = {
    designatedSecondaryAddressee: "yes" | "no";
    firstName?: string;
    lastName?: string;
    phone?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
};

type Props = {
    initialValues: Omit<AddresseeFields, "designatedSecondaryAddressee"> & {
        designatedSecondaryAddressee?: boolean;
    };

    onSubmit: SubmitHandler<AddresseeFields>;
    onError?: Function;
    content: AddresseeContent;
};

const AddresseeForm = ({ initialValues, onSubmit, onError, content }: Props) => {
    const {
        register,
        watch,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddresseeFields>({
        shouldUnregister: true,
        defaultValues: {
            designatedSecondaryAddressee: toLowerCaseYesNoFromBoolean(initialValues.designatedSecondaryAddressee) ?? undefined,
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            phone: initialValues.phone,
            addressLine1: initialValues.addressLine1,
            addressLine2: initialValues.addressLine2,
            city: initialValues.city,
            state: initialValues.state,
            zipCode: initialValues.zipCode,
        },
    });

    const { addresseePopoverHtml } = content;
    const {
        requiredFieldErrorMessage,
        requiredSelectErrorMessage,
        noSpecialCharsErrorMessage,
        firstNameMaxLength,
        lastNameMaxLength,
        validPhone,
        phoneLength,
    } = content;

    const onErrorWithLogging = (validationErrors: FieldErrors<AddresseeFields>) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    const watchedDesignatedSecondaryAddressee = watch("designatedSecondaryAddressee");

    return (
        <Container>
            <StyledForm id="addressee-form" data-testid="addressee-form" onSubmit={handleSubmit(onSubmit, onErrorWithLogging)}>
                <InputGroup>
                    <Legend>
                        Do you wish to designate a secondary addressee for this life insurance policy?
                        <Popover>
                            <div {...setRawHtml(addresseePopoverHtml)} />
                        </Popover>
                    </Legend>
                    <RadioGroup
                        id="designatedSecondaryAddressee"
                        direction="row"
                        options={[
                            {
                                value: "yes",
                                label: "Yes",
                            },
                            { value: "no", label: "No" },
                        ]}
                        {...register("designatedSecondaryAddressee", { required: requiredSelectErrorMessage })}
                    />
                    <FieldError error={errors.designatedSecondaryAddressee} />
                </InputGroup>

                {watchedDesignatedSecondaryAddressee === "yes" && (
                    <>
                        <InputGroup>
                            <SplitContainer>
                                <Label htmlFor="firstName">Secondary addressee's name</Label>
                                <InputHelpText>
                                    (<label htmlFor="firstName">First</label>, <label htmlFor="lastName">Last</label>)
                                </InputHelpText>
                            </SplitContainer>
                            <SpaceBetweenSplitContainer>
                                <NarrowInputGroup>
                                    <InputText
                                        id="firstName"
                                        autoComplete="given-name"
                                        maxLength={20}
                                        hasError={errors?.firstName}
                                        {...register("firstName", {
                                            required: requiredFieldErrorMessage,
                                            maxLength: {
                                                value: 20,
                                                message: firstNameMaxLength,
                                            },
                                            validate: (value) =>
                                                noSpecialCharactersRegex.test(value ?? "") || noSpecialCharsErrorMessage,
                                        })}
                                    />
                                </NarrowInputGroup>

                                <NarrowInputGroup>
                                    <InputText
                                        id="lastName"
                                        autoComplete="family-name"
                                        maxLength={29}
                                        hasError={errors?.lastName}
                                        {...register("lastName", {
                                            required: requiredFieldErrorMessage,
                                            maxLength: {
                                                value: 29,
                                                message: lastNameMaxLength,
                                            },
                                            validate: (value) =>
                                                noSpecialCharactersRegex.test(value ?? "") || noSpecialCharsErrorMessage,
                                        })}
                                    />
                                </NarrowInputGroup>
                            </SpaceBetweenSplitContainer>
                            <FieldError error={errors.firstName || errors.lastName} />
                        </InputGroup>

                        <AddressFields content={content} register={register} errors={errors} />

                        <NarrowInputGroup>
                            <Label htmlFor="phone">Phone number</Label>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 10,
                                        message: phoneLength,
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: phoneLength,
                                    },
                                    validate: (value) =>
                                        !value || isPhoneFormatValid(parseFormatted10DigitPhone(value)) || validPhone,
                                }}
                                render={({ field: { onChange, onBlur, name, value } }) => (
                                    <InputPhoneControlled
                                        id={name}
                                        name={name}
                                        hasError={errors?.phone}
                                        value={parseFormatted10DigitPhone(value || "")}
                                        onChange={(e) =>
                                            onChange(format10DigitNanpaPhone(parseFormatted10DigitPhone(e.target.value) || ""))
                                        }
                                        onBlur={onBlur}
                                    />
                                )}
                            />
                            <FieldError error={errors.phone} />
                        </NarrowInputGroup>
                    </>
                )}

                {Object.keys(errors).length > 0 && <ErrorMessage />}
            </StyledForm>
        </Container>
    );
};

export default AddresseeForm;

type AddressFieldsProps = {
    content: AddresseeContent;
    register: UseFormRegister<AddresseeFields>;
    errors: FieldErrors<AddresseeFields>;
};
function AddressFields({ content, register, errors }: AddressFieldsProps) {
    return (
        <>
            <InputGroup>
                <Label htmlFor="addressLine1">Street address</Label>
                <InputText
                    id="addressLine1"
                    placeholder="Address"
                    autoComplete="address-line1"
                    hasError={errors?.addressLine1}
                    maxLength={30}
                    {...register("addressLine1", {
                        required: content.requiredFieldErrorMessage,
                        maxLength: {
                            value: 30,
                            message: content.addressLine1MaxLength,
                        },
                        validate: (value) => noSpecialCharactersRegex.test(value ?? "") || content.noSpecialCharsErrorMessage,
                    })}
                />
            </InputGroup>
            <InputGroup>
                <InputText
                    id="addressLine2"
                    placeholder="Address line 2 (optional)"
                    autoComplete="address-line2"
                    hasError={errors?.addressLine2}
                    maxLength={30}
                    {...register("addressLine2", {
                        maxLength: {
                            value: 30,
                            message: content.addressLine2MaxLength,
                        },
                        validate: (value) => noSpecialCharactersRegex.test(value ?? "") || content.noSpecialCharsErrorMessage,
                    })}
                />
                <FieldError error={errors.addressLine1} />
                <FieldError error={errors.addressLine2} />
            </InputGroup>

            <InputGroup>
                <Label htmlFor="city">City</Label>
                <InputText
                    id="city"
                    autoComplete="address-level2"
                    hasError={errors?.city}
                    maxLength={20}
                    {...register("city", {
                        required: content.requiredFieldErrorMessage,
                        maxLength: {
                            value: 20,
                            message: content.cityMaxLength,
                        },
                        validate: (value) => noSpecialCharactersRegex.test(value ?? "") || content.noSpecialCharsErrorMessage,
                    })}
                />
                <FieldError error={errors.city} />
            </InputGroup>

            <SpaceBetweenSplitContainer>
                <NarrowInputGroup>
                    <Label id="state-label" htmlFor="state">
                        State
                    </Label>
                    <SelectNative
                        id="state"
                        placeholder="Select..."
                        aria-labelledby="state-label"
                        hasError={errors?.state}
                        {...register("state", {
                            required: content.requiredFieldErrorMessage,
                        })}
                    >
                        {STATE_SELECT_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </SelectNative>
                    <FieldError error={errors.state} />
                </NarrowInputGroup>

                <NarrowInputGroup>
                    <Label htmlFor="zipCode">ZIP code</Label>
                    <InputZipCode
                        id="zipCode"
                        autoComplete="postal-code"
                        inputMode="numeric"
                        onInput={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > 5) {
                                e.target.value = e.target.value.slice(0, 5);
                            }
                        }}
                        hasError={errors?.zipCode}
                        {...register("zipCode", {
                            required: content.requiredFieldErrorMessage,
                            minLength: {
                                value: 5,
                                message: content.zipLength,
                            },
                            maxLength: {
                                value: 5,
                                message: content.zipLength,
                            },
                            validate: (value) => digitOnlyRegex.test(value ?? "") || content.zipCanOnlyBeNumbers,
                        })}
                    />
                    <FieldError error={errors.zipCode} />
                </NarrowInputGroup>
            </SpaceBetweenSplitContainer>
        </>
    );
}
