export function toSingleLetterFromLowerCaseGender(lowerCaseGender?: string, defaultValue = null) {
    switch (lowerCaseGender) {
        case "male":
            return "M";
        case "female":
            return "F";
        default:
            return defaultValue;
    }
}
export function toLowerCaseGenderFromSingleLetter(singleLetter?: string, defaultValue = null) {
    switch (singleLetter) {
        case "M":
            return "male";
        case "F":
            return "female";
        default:
            return defaultValue;
    }
}
export function toUpperCaseGenderFromLowerCaseGender(gender: "male" | "female") {
    switch (gender) {
        case "male":
            return "MALE";
        case "female":
            return "FEMALE";
        default:
            throw new Error(`Unexpected gender '${gender}'.`);
    }
}
export function toBooleanFromLowerCaseYesNo(lowerCaseYesNo?: string, defaultValue = null) {
    switch (lowerCaseYesNo) {
        case "yes":
            return true;
        case "no":
            return false;
        default:
            return defaultValue;
    }
}
export function toLowerCaseYesNoFromBoolean(value?: boolean, defaultValue = null) {
    switch (value) {
        case true:
            return "yes";
        case false:
            return "no";
        default:
            return defaultValue;
    }
}
export function capitalize(value: string) {
    if (!value || typeof value !== "string" || value.length < 1) {
        return value;
    }

    return value.slice(0, 1).toUpperCase() + value.slice(1);
}

/**
 * Returns true for objects with a length property that has a 0 value.
 */
export function isEmpty(value: string | []) {
    return value !== null && typeof value !== "undefined" && value.hasOwnProperty("length") && value.length === 0;
}

export function emptyToDefault<T extends string | [], R>(value: T, defaultValue?: R) {
    return isEmpty(value) ? defaultValue : value;
}

export function emptyToUndefined<T extends string | []>(value: T) {
    return emptyToDefault<T, undefined>(value);
}

export function emptyToNull<T extends string | []>(value: T) {
    return emptyToDefault(value, null) ?? null;
}

export function map<T, R>(mappable: T | undefined, func: (value: T) => R) {
    return typeof mappable === "undefined" ? undefined : func(mappable);
}

/** A type which must be able to be null */
export type Nullable<T> = null extends T ? T : never;

/**
 * Function for coalescing any nullable value to the underlying value otherwise `undefined`. This function has a main purpose
 * of being used in place of the `??` operator directly in order to reduce "cognitive complexity" according to SonarQube. Use of
 * this function allows us to effectively avoid the cognitive complexity for a trade off some runtime performance in meantime
 * while we remove the concept of null from multiple places in our codebase. The use of the `Nullable<T>` type also allows us
 * to use the TypeScript complier to let us know where the use of the `valueOrUndefined` function is no longer needed since if
 * it is passed a value which cannot possibly be null then it will no longer compile in which case the call should simply be
 * removed in favor of using the value directly instead.
 * @param value A nullable value
 * @returns The actual value or undefinfed if null.
 */
export function valueOrUndefined<T>(value: Nullable<T>): NonNullable<T> | undefined {
    return value ?? undefined;
}
