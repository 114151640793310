import React, { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

const StyledSelect = styled.select<SelectStyles>`
    box-shadow: none;
    border-width: ${(props) => (props.hasError ? "2px" : "1px")};
    border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

    height: 38px;
    font-size: inherit;
    padding-left: 10px;
    border-style: solid;
    border-radius: 3px;

    &:focus-visible {
        outline: none;
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey7)};
    }

    &:hover {
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red;
        opacity: 1; /* Firefox */
    }
`;

const PlaceholderOption = styled.option`
    // color: ${(props) => props.theme.colors.grey5};
    // opacity: 1;
    display: none;
`;

type SelectStyles = {
    hasError?: FieldError | boolean;
};

type Props = {
    name: string;
    value?: string | number;
    placeholderValue?: string | number;
    disabled?: boolean;
    emptyOption?: boolean;
} & SelectStyles &
    React.HTMLAttributes<HTMLSelectElement>;

const SelectNative = forwardRef<HTMLSelectElement, Props>(
    (
        {
            id,
            name,
            placeholder,
            placeholderValue,
            value,
            onChange,
            disabled = false,
            hasError = false,
            children,
            emptyOption,
            ...rest
        },
        ref
    ) => (
        <StyledSelect
            id={id}
            name={name}
            data-testid={`test-${name}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            hasError={hasError}
            {...rest}
            ref={ref}
        >
            {typeof placeholder !== "undefined" && (
                <PlaceholderOption value={placeholderValue || ""}>{placeholder}</PlaceholderOption>
            )}
            {children}
        </StyledSelect>
    )
);

export default SelectNative;
