import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SplitColumn, PageDetails, ButtonGroup, InfoPanel } from "layout";
import Button from "components/inputs/button/Button";
import { H1, H2 } from "components/text";
import HealthStatementForm, { HealthStatementFormFields } from "features/forms/health-statement-form/HealthStatementForm";
import { setRawHtml } from "content/setRawHtml";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { useEffect } from "react";
import { toBooleanFromLowerCaseYesNo } from "utils/conversions";
import { contentReplacer } from "utils/contentReplacer";
import { ApplicationModeEnumSchema, makeBaseApplicationSchema } from "state/ApplicationStateSchema";
import { DTOPageEnum } from "constants/pageNames";
import { HealthStatementContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";
import { SubmitHandler } from "react-hook-form";

const pageName = DTOPageEnum.enum.healthStatement;

const ApplicationStep4Schema = z.object({
    state: z.string(),
    firstName: z.string(),
    hasUsedNicotineLastYear: z.boolean(),
    hasSevereMedicalConditions: z.boolean().nullish(),
    hadDiagnosticTesting: z.boolean().nullish(),
});

type ApplicationStep4 = z.infer<typeof ApplicationStep4Schema>;

const HealthStatementState = makeBaseApplicationSchema(
    ApplicationStep4Schema,
    z.object({
        applicationMode: ApplicationModeEnumSchema,
    })
);

const HealthStatement = () => {
    usePageTitle("Health Statement | AAA Life");
    useSendInteraction(pageName);

    const navigate = useNavigate();
    const { state, dispatch } = useFormState(pageName);
    const parseResult = HealthStatementState.safeParse(state);

    useEffect(() => {
        if (!parseResult.success) {
            console.error(parseResult.error);
            navigate("/system-error");
        }
    });

    const { result: content } = useContent({
        targetSchema: HealthStatementContentSchema,
        applicationMode: state?.applicationMode,
        pageName: "health-statement",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state,
    });

    if (!parseResult.success) {
        return null;
    }

    const applicationData: ApplicationStep4 = parseResult.data.application;

    const handleClickBack = () => {
        navigate("/app/secondary-addressee");
    };

    const handleClickNext: SubmitHandler<HealthStatementFormFields> = (submittedData) => {
        dispatch({
            type: "HEALTH_QUESTIONS_ANSWERED",
            hasSevereMedicalConditions: toBooleanFromLowerCaseYesNo(submittedData.hasSevereMedicalConditions)!,
            hadDiagnosticTesting: toBooleanFromLowerCaseYesNo(submittedData.hadDiagnosticTesting)!,
        });

        navigate("/app/payment-info");
    };

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{contentReplacer({ name: applicationData.firstName }, content.leftPanelTitle)}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <HealthStatementForm
                            initialValues={{
                                hasUsedNicotineLastYear: applicationData.hasUsedNicotineLastYear,
                                hasSevereMedicalConditions: applicationData.hasSevereMedicalConditions ?? undefined,
                                hadDiagnosticTesting: applicationData.hadDiagnosticTesting ?? undefined,
                            }}
                            onSubmit={handleClickNext}
                            onNavigate={navigate}
                            content={content}
                        />
                        <ButtonGroup>
                            <Button label={content.previousLabel} onClick={handleClickBack} />
                            <Button label={content.nextLabel} type="submit" color="primary" form="health-statement-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default HealthStatement;
