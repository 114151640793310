import Button from "components/inputs/button/Button";
import { H3 } from "components/text";
import { MouseEventHandler } from "react";
import styled from "styled-components";

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    & Button {
        align-self: center;
    }
`;

type ValidatedPaymentProps = {
    message: string;
    buttonLabel: string;
    onEdit?: MouseEventHandler<HTMLButtonElement>;
};

export const ValidatedPayment = ({ onEdit, message, buttonLabel }: ValidatedPaymentProps) => {
    return (
        <StyledSection>
            <H3>{message}</H3>
            <Button label={buttonLabel} color="secondary" onClick={onEdit} />
        </StyledSection>
    );
};
