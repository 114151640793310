import { useFormState } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { sendInteraction } from "services/userInteractionService";
import { formatInteractionData } from "utils/formatInteractionData";

/**
 * Sends an interaction details to backend server for tracking.
 * @param {import("../constants/pageNames").DTOPages} pageName the canonical name of the page
 * @returns {Promise<boolean>} true if successful otherwise false wrapped in a Promise object.
 */
export function useSendInteraction(pageName) {
    const { state } = useFormState();
    const [interactionData] = useState(() => formatInteractionData(state, pageName));
    const sendInteractionCallback = useCallback(() => {
        return sendInteraction(interactionData)
            .then(() => true)
            .catch((error) => {
                console.error(error);
                return false;
            });
    }, [interactionData]);

    useEffect(() => {
        sendInteractionCallback();
    }, [sendInteractionCallback]);
}
