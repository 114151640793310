import styled from "styled-components";
import { devices } from "constants/breakpoints";
import * as RadixPopover from "@radix-ui/react-popover";
import { ReactComponent as QuestionMarkIcon } from "assets/icons/circle_questionmark.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const IconWrapper = styled.span`
    margin-left: ${(props) => props.theme.spacing.padding.xs};
`;

const StyledQuestionIcon = styled(QuestionMarkIcon)`
    cursor: pointer;
    fill: currentColor;
    display: inline;

    color: ${(props) => props.theme.colors.blue};
    height: 14px;
    width: 14px;
`;

const PopoverContent = styled(RadixPopover.Content)`
    margin: 0;
    display: flex;
    border-radius: 2px;
    width: 260px;
    background-color: white;
    box-shadow: 1px 1px 5px 0 ${(props) => props.theme.colors.grey6};

    @media ${devices.minTablet} {
        padding: ${(props) => props.theme.spacing.padding.large};
    }

    @media ${devices.tablet} {
        padding: ${(props) => props.theme.spacing.padding.medium};
    }
`;

const PopoverClose = styled(RadixPopover.Close)`
    padding: 0;
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;

    height: 25px;
    width: 25px;

    color: ${(props) => props.theme.colors.blue};

    &:focus {
        box-shadow: 0 0 0 2px var(--violet7);
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    stroke: ${(props) => props.theme.colors.blue};
    width: 14px;
`;

const TriggerContainer = styled.span`
    /* display: flex; */
`;

const StyledDisplayText = styled.label`
    cursor: pointer;
    margin: 0;
    font-weight: 600;
    font-style: italic;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.blue};
`;

// https://www.radix-ui.com/docs/primitives/components/popover
const Popover = ({ displayText = "", children }) => (
    <RadixPopover.Root>
        <RadixPopover.Trigger asChild>
            <TriggerContainer>
                {displayText && <StyledDisplayText>{displayText}</StyledDisplayText>}
                <IconWrapper>
                    <StyledQuestionIcon />
                </IconWrapper>
            </TriggerContainer>
        </RadixPopover.Trigger>
        <RadixPopover.Anchor />
        <RadixPopover.Portal>
            <PopoverContent sideOffset={5}>
                <>
                    {children}
                    <PopoverClose aria-label="Close">
                        <StyledCloseIcon />
                    </PopoverClose>
                </>
            </PopoverContent>
        </RadixPopover.Portal>
    </RadixPopover.Root>
);

export default Popover;
