import { checkIdentity, isEligibleApplication } from "services/onlineApplicationService";
import { toBooleanFromLowerCaseYesNo, toSingleLetterFromLowerCaseGender, emptyToUndefined } from "utils/conversions";
import { parseFormatted10DigitPhone } from "utils/phone";

export const reservePolicy = async (applicationMode, data, policyNumber, hannoverId) => {
    if (applicationMode === "directterm") {
        return callCheckIdentity(data, policyNumber, hannoverId);
    } else if (applicationMode === "loyalty") {
        const response = await isEligibleApplication({ policyNumber: policyNumber });
        if (!response.policyNumber || typeof response.policyNumber !== "string" || response.policyNumber.length < 10) {
            throw new Error("Unable to get policy number from eligibility check.");
        }
        return {
            ...data,
            policyNumber: response.policyNumber,
        };
    } else {
        throw new Error("Unexpected application mode");
    }
};

const callCheckIdentity = async (data, policyNumber, hannoverId) => {
    // Transform the data into conventional values held in application state
    const transformedData = {
        firstName: data.firstName,
        middleInitial: emptyToUndefined(data.middleInitial),
        lastName: data.lastName,

        policyNumber: emptyToUndefined(policyNumber),
        hannoverId: emptyToUndefined(hannoverId),

        addressLine1: data.addressLine1,
        addressLine2: emptyToUndefined(data.addressLine2),
        city: data.city,
        state: data.state,
        phone: parseFormatted10DigitPhone(data.phone),
        phoneType: data.phoneType,
        email: data.email,

        height: { feet: parseInt(data?.height?.feet, 10), inches: parseInt(data?.height?.inches, 10) },
        weight: parseInt(data.weight, 10),
        willReplacePolicy: toBooleanFromLowerCaseYesNo(data.willReplacePolicy), // FUTURE: Check, may be a regression
        policyToReplace: {
            company: data.policyToReplace.company,
            policyNumber: emptyToUndefined(data.policyToReplace.policyNumber),
        },
    };

    /** @type {import("services/onlineApplicationService").CheckIdentityRequestBody} */
    const requestBody = {
        policyNumber: transformedData.policyNumber,
        hannoverID: transformedData.hannoverId,

        firstName: data.firstName,
        middleInitial: transformedData.middleInitial,
        lastName: data.lastName,
        birthDate: data.dateOfBirth,
        phone: transformedData.phone,

        address: {
            addressLine1: data.addressLine1,
            addressLine2: transformedData.addressLine2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            country: "USA",
        },
        gender: toSingleLetterFromLowerCaseGender(data.gender),
        email: data.email,
    };

    const response = await checkIdentity(requestBody);

    if (!response.policyNumber || typeof response.policyNumber !== "string" || response.policyNumber.length < 10) {
        throw new Error("Unable to get policy number from identity check.");
    }

    if (!response.hannoverId || typeof response.hannoverId !== "string") {
        throw new Error("Unable to get hannoverId from identity check.");
    }

    return {
        ...transformedData,
        policyNumber: response.policyNumber,
        hannoverId: response.hannoverId,
    };
};

export default reservePolicy;
