import { InputHTMLAttributes, forwardRef } from "react";
import { InputText } from "components/inputs";
import { digitOrAlphaRegex, nonDigitNorAlphaGlobalRegex } from "constants/validations";

interface InputInvitationCodeProps extends InputHTMLAttributes<HTMLInputElement> {
    hasError: boolean;
}

const InputInvitationCode = forwardRef(
    ({ onChange, onKeyDown, hasError, ...rest }: InputInvitationCodeProps, ref: React.Ref<HTMLInputElement>) => (
        <InputText
            placeholder=""
            maxLength={10}
            hasError={hasError}
            ref={ref}
            {...rest}
            onKeyDown={(e) => {
                if (
                    ["Delete", "Backspace", "Tab", "Esc", "Enter", "Home", "End", "ArrowLeft", "ArrowRight"].includes(e.key) ||
                    (e.ctrlKey === true && (e.key === "a" || e.key === "c" || e.key === "v" || e.key === "x")) ||
                    digitOrAlphaRegex.test(e.key)
                ) {
                    if (typeof onKeyDown === "function") {
                        onKeyDown(e);
                    }
                    return;
                }
                e.preventDefault();
            }}
            onChange={(e) => {
                const newValue = e.currentTarget.value.replace(nonDigitNorAlphaGlobalRegex, "");

                if (newValue !== e.currentTarget.value) {
                    e.currentTarget.value = newValue;
                }

                if (typeof onChange === "function") {
                    onChange(e);
                }
            }}
        />
    )
);

export default InputInvitationCode;
