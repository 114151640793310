import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { ButtonGroup } from "layout";
import Button from "components/inputs/button/Button";
import ChooseCoverageTier, { CoverageSelection } from "components/choose-coverage-tier/ChooseCoverageTier";
import { logErrors } from "utils/logErrors";
import { QuoteResultsContent } from "content/contentSchemas";
import { z } from "zod";
import { LoyaltyApplicationStep1Schema } from "./QuoteResultsStateSchema";
import { ErrorMessage } from "components/inputs";
import { ReplacedContent } from "utils/contentReplacer";

type LoyaltyQuoteResultsContainerFormFields = {
    coverage: CoverageSelection;
};

type LoyaltyQuoteResultsContainerProps = {
    content: QuoteResultsContent;
    placeholderValues: ReplacedContent;
    applicationData: z.infer<typeof LoyaltyApplicationStep1Schema>;
    showStateMismatchWarning: boolean;
    onError: (error: unknown) => void;
    handleClickBack: () => void;
    handleClickNext: (selection: CoverageSelection) => void;
};

const LoyaltyQuoteResultsContainer = ({
    content,
    placeholderValues,
    applicationData,
    showStateMismatchWarning,
    onError,
    handleClickBack,
    handleClickNext,
}: LoyaltyQuoteResultsContainerProps) => {
    // No selection should be made by default, the user must choose instead
    const initialCoverage = {
        coverageTier: applicationData.selectedCoverageTier,
        coverageType: applicationData.selectedCoverageType,
        coveragePremium: applicationData.selectedCoveragePremium,
        coverageAmount: applicationData.selectedCoverageAmount,
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoyaltyQuoteResultsContainerFormFields>({
        defaultValues: {
            coverage: initialCoverage,
        },
    });

    const onSubmit: SubmitHandler<LoyaltyQuoteResultsContainerFormFields> = async (selectedCoverage) => {
        try {
            handleClickNext(selectedCoverage.coverage);
        } catch (err) {
            console.error(err);
            if (onError) {
                onError(err);
            }
        }
    };

    const onErrorWithLogging: SubmitErrorHandler<LoyaltyQuoteResultsContainerFormFields> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    return (
        <>
            <form
                id="quote-results-form"
                data-testid="quote-results-form"
                onSubmit={handleSubmit(onSubmit, onErrorWithLogging)}
            >
                <Controller
                    control={control}
                    name="coverage"
                    rules={{
                        validate: (coverage) =>
                            Object.values(coverage).every((value) => !!value) || content.coverageOptionRequiredErrorMessage,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ChooseCoverageTier
                            content={content}
                            placeholderValues={placeholderValues}
                            coverageAmountOptions={applicationData.coverageOptions}
                            selectedCoverage={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    )}
                />

                {Object.keys(errors).length > 0 && (
                    <ErrorMessage text={errors?.coverage && content.coverageOptionRequiredErrorMessage} />
                )}
            </form>

            {showStateMismatchWarning && <p>{content.offerStateMismatchWarningMessage}</p>}

            <ButtonGroup>
                <Button label={content.previousLabel} onClick={handleClickBack} />
                <Button label={content.nextLabel} type="submit" color="primary" form="quote-results-form" />
            </ButtonGroup>
        </>
    );
};

export default LoyaltyQuoteResultsContainer;
