// disallow the following characters
// "(),:;<>[]&!%$+=?@
export const noSpecialCharactersRegex = /^[^"(),:;<>[\]&!%$+=?@À-ÖØ-öø-ÿ]*$/;

export const digitOnlyRegex = /^\d+$/;

export const digitOrEmptyRegex = /^\d*$/;

export const digitOrAlphaRegex = /^[a-zA-Z0-9]$/;

export const nonDigitNorAlphaGlobalRegex = /[^a-zA-Z0-9]/g;

export const alphaOnlyRegex = /^[a-zA-Z]*$/;

export const emailRegex =
    /^[a-zA-Z0-9!#$%'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

/** Only allow a valid invitation code which is also referred to as a finder number or an application ID.*/
export const invitationCodeRegex = /^[KMGZ][01][A-Z0-9]{8}$/i;
export const genericInvitationCodeRegex = /^[G][01][A-Z0-9]{8}$/i;
/** Almost the same as invitationCodeRegex except for that it accepts empty string as well. */
export const uidParameterRegex = /^([KMGZ][01][A-Z0-9]{8})?$/i;
/** Almost the same as invitationCodeRegex except for that it only accepts non-generic invitation codes. */
export const applicationIDRegex = /^[KMZ][01][A-Z0-9]{8}$/i;

export const invitationCodeFirstCharRegex = /^[MGZ]$/i;

export const invitationCodeSecondCharRegex = /^[0]$/;
