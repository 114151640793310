import styled from "styled-components";
import { RadioGroup } from "components/inputs";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { logErrors } from "utils/logErrors";
import { formatUSCurrency } from "utils/formatUSCurrency";
import { ReplacedContent, contentReplacer } from "utils/contentReplacer";
import { noop } from "constants/noop";
import { QuoteResultsContent } from "content/contentSchemas";

const OuterContainer = styled.div`
    max-width: 600px;
    text-align: center;

    background-color: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.grey7};
    padding: ${(props) => props.theme.spacing.padding.small};
    font-size: ${(props) => props.theme.font.size.h5};
    font-weight: ${(props) => props.theme.font.weight.bold};
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled.p`
    margin: 0;
    padding-bottom: ${(props) => props.theme.spacing.padding.small};
    color: ${(props) => props.theme.colors.blue};
    font-size: ${(props) => props.theme.font.size.h4};
`;

const CoveragePreview = styled.div`
    display: flex;

    background-color: ${(props) => props.theme.colors.background};
`;

const CoverageOptions = styled.div`
    text-align: left;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: ${(props) => props.theme.spacing.padding.small};
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
    padding-left: ${(props) => props.theme.spacing.padding.small};
    padding-right: ${(props) => props.theme.spacing.padding.small};
    border-right: 1px solid ${(props) => props.theme.colors.grey4};
`;

const MonthlyPayment = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.theme.spacing.padding.small};
`;

const PaymentTitle = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.blue};
    text-transform: uppercase;
`;

const PaymentAmount = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.grey7};
`;

export type QuoteResultsFormFields = {
    coverage: string;
};

type ChooseCoverageAmountProps = {
    content: QuoteResultsContent;
    coverageAmountOptions: { coverageAmount: number; monthlyPremium: number }[];
    selectedCoverageAmount?: number;
    placeholderValues: ReplacedContent;
    onSubmit: (submittedData: QuoteResultsFormFields) => void;
    onError?: SubmitErrorHandler<QuoteResultsFormFields>;
};

// FUTURE: Revise this. Components should be re-useable views with few dependencies.
const ChooseCoverageAmount = ({
    content,
    coverageAmountOptions,
    selectedCoverageAmount,
    placeholderValues,
    onSubmit,
    onError = noop,
}: ChooseCoverageAmountProps) => {
    const { register, handleSubmit, watch } = useForm<QuoteResultsFormFields>({
        defaultValues: {
            coverage: (selectedCoverageAmount || coverageAmountOptions[0].coverageAmount).toString(),
        },
    });

    const watchCoverage = parseInt(watch("coverage"), 10);
    const selectedCoveragePremium = coverageAmountOptions.find(
        (option) => option.coverageAmount === watchCoverage
    )?.monthlyPremium;

    const onErrorWithLogging: SubmitErrorHandler<QuoteResultsFormFields> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    return (
        <StyledForm
            id="quote-results-form"
            data-testid="quote-results-form"
            onSubmit={handleSubmit(onSubmit, onErrorWithLogging)}
        >
            <OuterContainer>
                <Title>{contentReplacer(placeholderValues, content.quoteSummaryGreetingTemplate)}</Title>
                <CoveragePreview>
                    <CoverageOptions>
                        {content.coverageOptionsLabel}
                        <RadioGroup
                            direction="column"
                            options={coverageAmountOptions.map((option, index) => ({
                                value: option.coverageAmount,
                                label: formatUSCurrency(option.coverageAmount, false),
                            }))}
                            rowSeparator={true}
                            {...register("coverage", { required: content.coverageOptionRequiredErrorMessage })}
                        />
                    </CoverageOptions>
                    <MonthlyPayment>
                        <PaymentTitle>{content.monthlyPaymentLabel}</PaymentTitle>
                        <PaymentAmount>{formatUSCurrency(selectedCoveragePremium)}</PaymentAmount>
                    </MonthlyPayment>
                </CoveragePreview>
            </OuterContainer>
        </StyledForm>
    );
};

export default ChooseCoverageAmount;
