import { ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import { z } from "zod";
import { post_GetDirectTermOnlineQuote, post_GetMLTA_OnlineQuote } from "./online-application-quote-service.openapi.zod";

type DirectTermOnlineQuoteRequestBody = z.infer<typeof post_GetDirectTermOnlineQuote.parameters.shape.body>;
type DirectTermOnlineQuoteResponseBody = z.infer<typeof post_GetDirectTermOnlineQuote.response>;

export async function getDirectTermOnlineQuote(
    body: DirectTermOnlineQuoteRequestBody
): Promise<DirectTermOnlineQuoteResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL}/direct-term-online-quote`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    if (response.ok) {
        return post_GetDirectTermOnlineQuote.response.parse(await response.json());
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error("Unable to find quotes.");
    } else {
        console.warn(response);
        throw new Error("Unexpected response to request to find quotes.");
    }
}

type LoyaltyOfferRequestBody = z.infer<typeof post_GetMLTA_OnlineQuote.parameters.shape.body>;
const LoyaltyOfferResponseBodySchema = post_GetMLTA_OnlineQuote.response;
type LoyaltyOfferResponseBody = z.infer<typeof LoyaltyOfferResponseBodySchema>;

export async function getMLTAOnlineQuote(body: LoyaltyOfferRequestBody): Promise<LoyaltyOfferResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_QUOTE_SERVICE_BASE_URL}/mlta-online-quote`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    if (response.ok) {
        const json = await response.json();
        return LoyaltyOfferResponseBodySchema.parse(json);
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error("Unable to find quotes.");
    } else {
        console.warn(response);
        throw new Error("Unexpected response to request to find quotes.");
    }
}
