import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { H3 } from "components/text";
import { Paths } from "constants/pageNames";

const StyledReviewHeader = styled.div`
    display: flex;
    align-items: end;
`;

const ReviewEditLink = styled.button`
    font-size: ${(props) => props.theme.font.size.text};
    color: ${(props) => props.theme.colors.red};
    margin-bottom: 16px;
    margin-left: ${(props) => props.theme.spacing.padding.small};
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
`;

export type ReviewHeaderProps = {
    title: string;
    linkTo: Paths;
};

const ReviewHeader = ({ title, linkTo }: ReviewHeaderProps) => {
    const navigate = useNavigate();

    return (
        <StyledReviewHeader>
            <H3>{title}</H3>
            <ReviewEditLink onClick={() => navigate(linkTo)}>Edit</ReviewEditLink>
        </StyledReviewHeader>
    );
};

export default ReviewHeader;
