type HeightOption = { value: string; label: string };

const HEIGHT_FEET_SELECT_OPTIONS: HeightOption[] = [
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
];

const HEIGHT_INCHES_SELECT_OPTIONS: HeightOption[] = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
];

export { HEIGHT_FEET_SELECT_OPTIONS, HEIGHT_INCHES_SELECT_OPTIONS };
