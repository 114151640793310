import { useEffect, useMemo } from "react";
import { useFormState } from "hooks";
import { clearSessionState } from "state/session";
import { ApplicationStates } from "state/ApplicationStateSchema";
import { DTOPages } from "constants/pageNames";

/**
 * A convenience hook which combines common steps regarding state and behavior for each terminal decision page.
 * @param pageName - the unique name assigned to the page
 * @param componentStateSelector - selects the content of component state from the application application state
 */
export function useFormCompletion<T>(pageName: DTOPages, componentStateSelector: (state: ApplicationStates) => T) {
    const { state, dispatch } = useFormState(pageName);
    const data = useMemo(() => componentStateSelector(state), [componentStateSelector, state]);
    useEffect(() => {
        clearSessionState();
        dispatch({ type: "APPLICATION_COMPLETED" });
    }, [dispatch]);

    return [data];
}
