import styled from "styled-components";
import { theme } from "constants/theme";
import { MoonLoader } from "react-spinners";

// https://www.davidhu.io/react-spinners/storybook/?path=/docs/moonloader--main
const StyledLoader = styled(MoonLoader)`
    align-self: center;
    margin: 32px;
`;

const Loader = ({ color = theme.colors.blue, size = 60, speedMultiplier = 0.5, loading = true }) => (
    <StyledLoader color={color} size={size} speedMultiplier={speedMultiplier} loading={loading} />
);

export default Loader;
