import { z } from "zod";

export type DTORequest = z.infer<typeof DTORequest>;
export const DTORequest = z.object({
    state: z
        .union([
            z.literal("AL"),
            z.literal("AK"),
            z.literal("AZ"),
            z.literal("AR"),
            z.literal("CA"),
            z.literal("CO"),
            z.literal("CT"),
            z.literal("DE"),
            z.literal("DC"),
            z.literal("FL"),
            z.literal("GA"),
            z.literal("HI"),
            z.literal("ID"),
            z.literal("IL"),
            z.literal("IN"),
            z.literal("IA"),
            z.literal("KS"),
            z.literal("KY"),
            z.literal("LA"),
            z.literal("ME"),
            z.literal("MD"),
            z.literal("MA"),
            z.literal("MI"),
            z.literal("MN"),
            z.literal("MS"),
            z.literal("MO"),
            z.literal("MT"),
            z.literal("NE"),
            z.literal("NV"),
            z.literal("NH"),
            z.literal("NJ"),
            z.literal("NM"),
            z.literal("NY"),
            z.literal("NC"),
            z.literal("ND"),
            z.literal("OH"),
            z.literal("OK"),
            z.literal("OR"),
            z.literal("PA"),
            z.literal("RI"),
            z.literal("SC"),
            z.literal("SD"),
            z.literal("TN"),
            z.literal("TX"),
            z.literal("UT"),
            z.literal("VT"),
            z.literal("VA"),
            z.literal("WA"),
            z.literal("WV"),
            z.literal("WI"),
            z.literal("WY"),
        ])
        .optional(),
    gender: z.union([z.literal("MALE"), z.literal("FEMALE")]).optional(),
    month: z.string().optional(),
    year: z.string().optional(),
    day: z.string().optional(),
    zipCode: z.string().optional(),
    useNicotine: z.boolean().optional(),
    productCode: z.string().optional(),
    faceAmounts: z.array(z.number()).optional(),
});

export type MLTARequest = z.infer<typeof MLTARequest>;
export const MLTARequest = z.object({
    dateOfBirth: z.string(),
    membershipLength: z.number(),
    state: z.union([
        z.literal("AL"),
        z.literal("AK"),
        z.literal("AZ"),
        z.literal("AR"),
        z.literal("CA"),
        z.literal("CO"),
        z.literal("CT"),
        z.literal("DE"),
        z.literal("DC"),
        z.literal("FL"),
        z.literal("GA"),
        z.literal("HI"),
        z.literal("ID"),
        z.literal("IL"),
        z.literal("IN"),
        z.literal("IA"),
        z.literal("KS"),
        z.literal("KY"),
        z.literal("LA"),
        z.literal("ME"),
        z.literal("MD"),
        z.literal("MA"),
        z.literal("MI"),
        z.literal("MN"),
        z.literal("MS"),
        z.literal("MO"),
        z.literal("MT"),
        z.literal("NE"),
        z.literal("NV"),
        z.literal("NH"),
        z.literal("NJ"),
        z.literal("NM"),
        z.literal("NY"),
        z.literal("NC"),
        z.literal("ND"),
        z.literal("OH"),
        z.literal("OK"),
        z.literal("OR"),
        z.literal("PA"),
        z.literal("RI"),
        z.literal("SC"),
        z.literal("SD"),
        z.literal("TN"),
        z.literal("TX"),
        z.literal("UT"),
        z.literal("VT"),
        z.literal("VA"),
        z.literal("WA"),
        z.literal("WV"),
        z.literal("WI"),
        z.literal("WY"),
    ]),
    product: z.union([z.literal("MLTA"), z.literal("ACLTA")]),
    tiers: z.union([z.array(z.string()), z.undefined()]).optional(),
    keyCode: z.union([z.string(), z.undefined()]).optional(),
});

export type DTOQuoteResponse = z.infer<typeof DTOQuoteResponse>;
export const DTOQuoteResponse = z.object({
    quotes: z
        .array(
            z.object({
                coverageAmount: z.number().optional(),
                totalPremiumAmount: z.number().optional(),
            })
        )
        .optional(),
});

export type MLTAQuoteResponse = z.infer<typeof MLTAQuoteResponse>;
export const MLTAQuoteResponse = z.object({
    benefits: z
        .array(
            z.object({
                tier: z.string().optional(),
                level: z.number().optional(),
                airline: z.number().optional(),
                common: z.number().optional(),
                loss_of_life: z.number().optional(),
                hospital: z.number().optional(),
                yearly_er: z.number().optional(),
                all_accident: z.number().optional(),
                state: z
                    .union([
                        z.literal("AL"),
                        z.literal("AK"),
                        z.literal("AZ"),
                        z.literal("AR"),
                        z.literal("CA"),
                        z.literal("CO"),
                        z.literal("CT"),
                        z.literal("DE"),
                        z.literal("DC"),
                        z.literal("FL"),
                        z.literal("GA"),
                        z.literal("HI"),
                        z.literal("ID"),
                        z.literal("IL"),
                        z.literal("IN"),
                        z.literal("IA"),
                        z.literal("KS"),
                        z.literal("KY"),
                        z.literal("LA"),
                        z.literal("ME"),
                        z.literal("MD"),
                        z.literal("MA"),
                        z.literal("MI"),
                        z.literal("MN"),
                        z.literal("MS"),
                        z.literal("MO"),
                        z.literal("MT"),
                        z.literal("NE"),
                        z.literal("NV"),
                        z.literal("NH"),
                        z.literal("NJ"),
                        z.literal("NM"),
                        z.literal("NY"),
                        z.literal("NC"),
                        z.literal("ND"),
                        z.literal("OH"),
                        z.literal("OK"),
                        z.literal("OR"),
                        z.literal("PA"),
                        z.literal("RI"),
                        z.literal("SC"),
                        z.literal("SD"),
                        z.literal("TN"),
                        z.literal("TX"),
                        z.literal("UT"),
                        z.literal("VT"),
                        z.literal("VA"),
                        z.literal("WA"),
                        z.literal("WV"),
                        z.literal("WI"),
                        z.literal("WY"),
                    ])
                    .optional(),
                minAge: z.number().optional(),
                maxAge: z.number().optional(),
                individual: z.number().optional(),
                family: z.number().optional(),
                correctKeycode: z.string().optional(),
            })
        )
        .optional(),
});

export type Quotes = z.infer<typeof Quotes>;
export const Quotes = z.array(
    z.object({
        coverageAmount: z.number().optional(),
        totalPremiumAmount: z.number().optional(),
    })
);

export type MLTAQuoteResponseData = z.infer<typeof MLTAQuoteResponseData>;
export const MLTAQuoteResponseData = z.array(
    z.object({
        tier: z.string().optional(),
        level: z.number().optional(),
        airline: z.number().optional(),
        common: z.number().optional(),
        loss_of_life: z.number().optional(),
        hospital: z.number().optional(),
        yearly_er: z.number().optional(),
        all_accident: z.number().optional(),
        state: z
            .union([
                z.literal("AL"),
                z.literal("AK"),
                z.literal("AZ"),
                z.literal("AR"),
                z.literal("CA"),
                z.literal("CO"),
                z.literal("CT"),
                z.literal("DE"),
                z.literal("DC"),
                z.literal("FL"),
                z.literal("GA"),
                z.literal("HI"),
                z.literal("ID"),
                z.literal("IL"),
                z.literal("IN"),
                z.literal("IA"),
                z.literal("KS"),
                z.literal("KY"),
                z.literal("LA"),
                z.literal("ME"),
                z.literal("MD"),
                z.literal("MA"),
                z.literal("MI"),
                z.literal("MN"),
                z.literal("MS"),
                z.literal("MO"),
                z.literal("MT"),
                z.literal("NE"),
                z.literal("NV"),
                z.literal("NH"),
                z.literal("NJ"),
                z.literal("NM"),
                z.literal("NY"),
                z.literal("NC"),
                z.literal("ND"),
                z.literal("OH"),
                z.literal("OK"),
                z.literal("OR"),
                z.literal("PA"),
                z.literal("RI"),
                z.literal("SC"),
                z.literal("SD"),
                z.literal("TN"),
                z.literal("TX"),
                z.literal("UT"),
                z.literal("VT"),
                z.literal("VA"),
                z.literal("WA"),
                z.literal("WV"),
                z.literal("WI"),
                z.literal("WY"),
            ])
            .optional(),
        minAge: z.number().optional(),
        maxAge: z.number().optional(),
        individual: z.number().optional(),
        family: z.number().optional(),
        correctKeycode: z.string().optional(),
    })
);

export type Quote = z.infer<typeof Quote>;
export const Quote = z.object({
    coverageAmount: z.number().optional(),
    totalPremiumAmount: z.number().optional(),
});

export type MLTAResponseData = z.infer<typeof MLTAResponseData>;
export const MLTAResponseData = z.object({
    tier: z.string().optional(),
    level: z.number().optional(),
    airline: z.number().optional(),
    common: z.number().optional(),
    loss_of_life: z.number().optional(),
    hospital: z.number().optional(),
    yearly_er: z.number().optional(),
    all_accident: z.number().optional(),
    state: z
        .union([
            z.literal("AL"),
            z.literal("AK"),
            z.literal("AZ"),
            z.literal("AR"),
            z.literal("CA"),
            z.literal("CO"),
            z.literal("CT"),
            z.literal("DE"),
            z.literal("DC"),
            z.literal("FL"),
            z.literal("GA"),
            z.literal("HI"),
            z.literal("ID"),
            z.literal("IL"),
            z.literal("IN"),
            z.literal("IA"),
            z.literal("KS"),
            z.literal("KY"),
            z.literal("LA"),
            z.literal("ME"),
            z.literal("MD"),
            z.literal("MA"),
            z.literal("MI"),
            z.literal("MN"),
            z.literal("MS"),
            z.literal("MO"),
            z.literal("MT"),
            z.literal("NE"),
            z.literal("NV"),
            z.literal("NH"),
            z.literal("NJ"),
            z.literal("NM"),
            z.literal("NY"),
            z.literal("NC"),
            z.literal("ND"),
            z.literal("OH"),
            z.literal("OK"),
            z.literal("OR"),
            z.literal("PA"),
            z.literal("RI"),
            z.literal("SC"),
            z.literal("SD"),
            z.literal("TN"),
            z.literal("TX"),
            z.literal("UT"),
            z.literal("VT"),
            z.literal("VA"),
            z.literal("WA"),
            z.literal("WV"),
            z.literal("WI"),
            z.literal("WY"),
        ])
        .optional(),
    minAge: z.number().optional(),
    maxAge: z.number().optional(),
    individual: z.number().optional(),
    family: z.number().optional(),
    correctKeycode: z.string().optional(),
});

export type ServiceError = z.infer<typeof ServiceError>;
export const ServiceError = z.object({
    code: z.union([z.string(), z.undefined()]).optional(),
    message: z.string(),
});

export type AllowedStates = z.infer<typeof AllowedStates>;
export const AllowedStates = z.union([
    z.literal("AL"),
    z.literal("AK"),
    z.literal("AZ"),
    z.literal("AR"),
    z.literal("CA"),
    z.literal("CO"),
    z.literal("CT"),
    z.literal("DE"),
    z.literal("DC"),
    z.literal("FL"),
    z.literal("GA"),
    z.literal("HI"),
    z.literal("ID"),
    z.literal("IL"),
    z.literal("IN"),
    z.literal("IA"),
    z.literal("KS"),
    z.literal("KY"),
    z.literal("LA"),
    z.literal("ME"),
    z.literal("MD"),
    z.literal("MA"),
    z.literal("MI"),
    z.literal("MN"),
    z.literal("MS"),
    z.literal("MO"),
    z.literal("MT"),
    z.literal("NE"),
    z.literal("NV"),
    z.literal("NH"),
    z.literal("NJ"),
    z.literal("NM"),
    z.literal("NY"),
    z.literal("NC"),
    z.literal("ND"),
    z.literal("OH"),
    z.literal("OK"),
    z.literal("OR"),
    z.literal("PA"),
    z.literal("RI"),
    z.literal("SC"),
    z.literal("SD"),
    z.literal("TN"),
    z.literal("TX"),
    z.literal("UT"),
    z.literal("VT"),
    z.literal("VA"),
    z.literal("WA"),
    z.literal("WV"),
    z.literal("WI"),
    z.literal("WY"),
]);

export type Tiers = z.infer<typeof Tiers>;
export const Tiers = z.string();

export type post_GetDirectTermOnlineQuote = typeof post_GetDirectTermOnlineQuote;
export const post_GetDirectTermOnlineQuote = {
    method: z.literal("POST"),
    path: z.literal("/direct-term-online-quote"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        body: z.object({
            state: z
                .union([
                    z.literal("AL"),
                    z.literal("AK"),
                    z.literal("AZ"),
                    z.literal("AR"),
                    z.literal("CA"),
                    z.literal("CO"),
                    z.literal("CT"),
                    z.literal("DE"),
                    z.literal("DC"),
                    z.literal("FL"),
                    z.literal("GA"),
                    z.literal("HI"),
                    z.literal("ID"),
                    z.literal("IL"),
                    z.literal("IN"),
                    z.literal("IA"),
                    z.literal("KS"),
                    z.literal("KY"),
                    z.literal("LA"),
                    z.literal("ME"),
                    z.literal("MD"),
                    z.literal("MA"),
                    z.literal("MI"),
                    z.literal("MN"),
                    z.literal("MS"),
                    z.literal("MO"),
                    z.literal("MT"),
                    z.literal("NE"),
                    z.literal("NV"),
                    z.literal("NH"),
                    z.literal("NJ"),
                    z.literal("NM"),
                    z.literal("NY"),
                    z.literal("NC"),
                    z.literal("ND"),
                    z.literal("OH"),
                    z.literal("OK"),
                    z.literal("OR"),
                    z.literal("PA"),
                    z.literal("RI"),
                    z.literal("SC"),
                    z.literal("SD"),
                    z.literal("TN"),
                    z.literal("TX"),
                    z.literal("UT"),
                    z.literal("VT"),
                    z.literal("VA"),
                    z.literal("WA"),
                    z.literal("WV"),
                    z.literal("WI"),
                    z.literal("WY"),
                ])
                .optional(),
            gender: z.union([z.literal("MALE"), z.literal("FEMALE")]).optional(),
            month: z.string().optional(),
            year: z.string().optional(),
            day: z.string().optional(),
            zipCode: z.string().optional(),
            useNicotine: z.boolean().optional(),
            productCode: z.string().optional(),
            faceAmounts: z.array(z.number()).optional(),
        }),
    }),
    response: z.object({
        quotes: z
            .array(
                z.object({
                    coverageAmount: z.number().optional(),
                    totalPremiumAmount: z.number().optional(),
                })
            )
            .optional(),
    }),
};

export type post_GetMLTA_OnlineQuote = typeof post_GetMLTA_OnlineQuote;
export const post_GetMLTA_OnlineQuote = {
    method: z.literal("POST"),
    path: z.literal("/mlta-online-quote"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        body: z.object({
            dateOfBirth: z.string(),
            membershipLength: z.number(),
            state: z.union([
                z.literal("AL"),
                z.literal("AK"),
                z.literal("AZ"),
                z.literal("AR"),
                z.literal("CA"),
                z.literal("CO"),
                z.literal("CT"),
                z.literal("DE"),
                z.literal("DC"),
                z.literal("FL"),
                z.literal("GA"),
                z.literal("HI"),
                z.literal("ID"),
                z.literal("IL"),
                z.literal("IN"),
                z.literal("IA"),
                z.literal("KS"),
                z.literal("KY"),
                z.literal("LA"),
                z.literal("ME"),
                z.literal("MD"),
                z.literal("MA"),
                z.literal("MI"),
                z.literal("MN"),
                z.literal("MS"),
                z.literal("MO"),
                z.literal("MT"),
                z.literal("NE"),
                z.literal("NV"),
                z.literal("NH"),
                z.literal("NJ"),
                z.literal("NM"),
                z.literal("NY"),
                z.literal("NC"),
                z.literal("ND"),
                z.literal("OH"),
                z.literal("OK"),
                z.literal("OR"),
                z.literal("PA"),
                z.literal("RI"),
                z.literal("SC"),
                z.literal("SD"),
                z.literal("TN"),
                z.literal("TX"),
                z.literal("UT"),
                z.literal("VT"),
                z.literal("VA"),
                z.literal("WA"),
                z.literal("WV"),
                z.literal("WI"),
                z.literal("WY"),
            ]),
            product: z.union([z.literal("MLTA"), z.literal("ACLTA")]),
            tiers: z.union([z.array(z.string()), z.undefined()]).optional(),
            keyCode: z.union([z.string(), z.undefined()]).optional(),
        }),
    }),
    response: z.object({
        benefits: z
            .array(
                z.object({
                    tier: z.string().optional(),
                    level: z.number().optional(),
                    airline: z.number().optional(),
                    common: z.number().optional(),
                    loss_of_life: z.number().optional(),
                    hospital: z.number().optional(),
                    yearly_er: z.number().optional(),
                    all_accident: z.number().optional(),
                    state: z
                        .union([
                            z.literal("AL"),
                            z.literal("AK"),
                            z.literal("AZ"),
                            z.literal("AR"),
                            z.literal("CA"),
                            z.literal("CO"),
                            z.literal("CT"),
                            z.literal("DE"),
                            z.literal("DC"),
                            z.literal("FL"),
                            z.literal("GA"),
                            z.literal("HI"),
                            z.literal("ID"),
                            z.literal("IL"),
                            z.literal("IN"),
                            z.literal("IA"),
                            z.literal("KS"),
                            z.literal("KY"),
                            z.literal("LA"),
                            z.literal("ME"),
                            z.literal("MD"),
                            z.literal("MA"),
                            z.literal("MI"),
                            z.literal("MN"),
                            z.literal("MS"),
                            z.literal("MO"),
                            z.literal("MT"),
                            z.literal("NE"),
                            z.literal("NV"),
                            z.literal("NH"),
                            z.literal("NJ"),
                            z.literal("NM"),
                            z.literal("NY"),
                            z.literal("NC"),
                            z.literal("ND"),
                            z.literal("OH"),
                            z.literal("OK"),
                            z.literal("OR"),
                            z.literal("PA"),
                            z.literal("RI"),
                            z.literal("SC"),
                            z.literal("SD"),
                            z.literal("TN"),
                            z.literal("TX"),
                            z.literal("UT"),
                            z.literal("VT"),
                            z.literal("VA"),
                            z.literal("WA"),
                            z.literal("WV"),
                            z.literal("WI"),
                            z.literal("WY"),
                        ])
                        .optional(),
                    minAge: z.number().optional(),
                    maxAge: z.number().optional(),
                    individual: z.number().optional(),
                    family: z.number().optional(),
                    correctKeycode: z.string().optional(),
                })
            )
            .optional(),
    }),
};

// <EndpointByMethod>
export const EndpointByMethod = {
    post: {
        "/direct-term-online-quote": post_GetDirectTermOnlineQuote,
        "/mlta-online-quote": post_GetMLTA_OnlineQuote,
    },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
    body?: unknown;
    query?: Record<string, unknown>;
    header?: Record<string, unknown>;
    path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
    parameters?: EndpointParameters | undefined;
    response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
    operationId: string;
    method: Method;
    path: string;
    requestFormat: RequestFormat;
    parameters?: TConfig["parameters"];
    meta: {
        alias: string;
        hasParameters: boolean;
        areParametersRequired: boolean;
    };
    response: TConfig["response"];
};

type Fetcher = (method: Method, url: string, parameters?: EndpointParameters | undefined) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
    [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
    baseUrl: string = "";

    constructor(public fetcher: Fetcher) {}

    setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;
        return this;
    }

    // <ApiClient.post>
    post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
        path: Path,
        ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
    ): Promise<z.infer<TEndpoint["response"]>> {
        return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
    }
    // </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
    return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
