import styled from "styled-components";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { RadioGroup, ErrorMessage } from "components/inputs";
import { FieldError } from "components/FieldError";
import EditableField from "components/editable-field/EditableField";
import { capitalize, toLowerCaseYesNoFromBoolean } from "utils/conversions";
import { logErrors } from "utils/logErrors";
import { setRawHtml } from "content/setRawHtml";
import { HealthStatementContent } from "content/contentSchemas";
import { noop } from "constants/noop";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const StyledFieldSet = styled.fieldset`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
    border: 0;
    padding: 0;
`;

const Legend = styled.legend`
    display: flex;
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
`;

const EditableFieldContainer = styled.div`
    padding-left: 22px;
`;

const PaddedContainer = styled.div`
    padding-left: ${(props) => props.theme.spacing.padding.small};
`;

// FUTURE: Move translations to inside the form component and remove expose keyword
export type HealthStatementFormFields = {
    hasUsedNicotineLastYear: "yes" | "no";
    hasSevereMedicalConditions: "yes" | "no";
    hadDiagnosticTesting: "yes" | "no";
};

export type HealthStatementFormInitialValues = {
    hasUsedNicotineLastYear: boolean;
    hasSevereMedicalConditions?: boolean;
    hadDiagnosticTesting?: boolean;
};

type HealthStatementFormProps = {
    initialValues: HealthStatementFormInitialValues;
    onSubmit: SubmitHandler<HealthStatementFormFields>;
    onError?: (errors: any) => void;
    onNavigate: (url: string) => void;
    content: HealthStatementContent;
};

const HealthStatementForm = ({ initialValues, onSubmit, onError = noop, onNavigate, content }: HealthStatementFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<HealthStatementFormFields>({
        defaultValues: {
            hasUsedNicotineLastYear: toLowerCaseYesNoFromBoolean(initialValues.hasUsedNicotineLastYear) ?? undefined,
            hasSevereMedicalConditions: toLowerCaseYesNoFromBoolean(initialValues.hasSevereMedicalConditions) ?? undefined,
            hadDiagnosticTesting: toLowerCaseYesNoFromBoolean(initialValues.hadDiagnosticTesting) ?? undefined,
        },
    });

    const { requiredSelectErrorMessage } = content;

    const onErrorWithLogging: SubmitErrorHandler<HealthStatementFormFields> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    return (
        <Container>
            <StyledForm
                id="health-statement-form"
                data-testid="health-statement-form"
                onSubmit={handleSubmit(onSubmit, onErrorWithLogging)}
            >
                <InputGroup>
                    <Legend>
                        <b>1.&nbsp;</b>
                        {content.nicotineQuestion}
                    </Legend>
                    <EditableFieldContainer>
                        <EditableField
                            value={capitalize(toLowerCaseYesNoFromBoolean(initialValues.hasUsedNicotineLastYear)!)}
                            onEdit={() => onNavigate("/quote")}
                        />
                    </EditableFieldContainer>
                </InputGroup>

                <StyledFieldSet>
                    <Legend>
                        <b>2.&nbsp;</b>
                        <span {...setRawHtml(content.severeMedicalQuestionHtml)} />
                    </Legend>
                    <PaddedContainer>
                        <RadioGroup
                            id="hasSevereMedicalConditions"
                            direction="row"
                            options={[
                                {
                                    value: "yes",
                                    label: "Yes",
                                },
                                { value: "no", label: "No" },
                            ]}
                            {...register("hasSevereMedicalConditions", { required: requiredSelectErrorMessage })}
                        />
                    </PaddedContainer>
                    <FieldError error={errors.hasSevereMedicalConditions} />
                </StyledFieldSet>

                <StyledFieldSet>
                    <Legend>
                        <b>3.&nbsp;</b>
                        {content.diagnosticTestingQuestion}
                    </Legend>
                    <PaddedContainer>
                        <RadioGroup
                            id="hadDiagnosticTesting"
                            direction="row"
                            options={[
                                {
                                    value: "yes",
                                    label: "Yes",
                                },
                                { value: "no", label: "No" },
                            ]}
                            {...register("hadDiagnosticTesting", { required: requiredSelectErrorMessage })}
                        />
                    </PaddedContainer>
                    <FieldError error={errors.hadDiagnosticTesting} />
                </StyledFieldSet>

                {Object.keys(errors).length > 0 && <ErrorMessage />}
            </StyledForm>
        </Container>
    );
};

export default HealthStatementForm;
