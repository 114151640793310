import { createContext, useState } from "react";

export const IdleTimerContext = createContext();

const IdleTimerProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    return <IdleTimerContext.Provider value={{ open, setOpen }}>{children}</IdleTimerContext.Provider>;
};

export default IdleTimerProvider;
