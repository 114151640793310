import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Segment from "components/segment/Segment";
import Radio from "components/inputs/radio/Radio";
import { devices } from "constants/breakpoints";
import { formatUSCurrency } from "utils/formatUSCurrency";
import { QuoteResultsContent } from "content/contentSchemas";
import { BenefitOption, CoverageTypes } from "state/ApplicationStateSchema";
import { noop } from "constants/noop";
import { ReplacedContent, contentReplacer } from "utils/contentReplacer";
import { setRawHtml } from "content/setRawHtml";
import { useResizeObserver } from "hooks";

const OuterContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const Title = styled.p`
    margin: 0;
    padding: ${(props) => props.theme.spacing.padding.small};
    color: ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.lightBlue};
    font-weight: ${(props) => props.theme.font.weight.bold};

    @media ${devices.minMobile} {
        font-size: ${(props) => props.theme.font.size.h4};
    }

    @media ${devices.mobile} {
        font-size: ${(props) => props.theme.font.size.h5};
    }
`;

const DesktopHeader = styled.div`
    @media ${devices.small} {
        display: none;
    }
`;

const MobileHeader = styled.div`
    display: inline;

    @media ${devices.minSmall} {
        display: none;
    }
`;

const Grid = styled.div<{ tierCount: number }>`
    display: grid;

    @media ${devices.minSmall} {
        grid-template-columns: ${(props) => (props.tierCount === 2 ? "2fr 1fr 1fr;" : "2fr 1fr;")};
    }

    @media ${devices.small} {
        grid-template-columns: 2fr 1fr;
    }

    @media ${devices.minDesktop} {
        font-size: ${(props) => props.theme.font.size.h6};
    }

    @media ${devices.desktop} {
        font-size: ${(props) => props.theme.font.size.text};
    }
`;

const ColumnGrid = styled(Grid)`
    grid-auto-flow: column;
`;

const TravelAccidentGrid = styled(ColumnGrid)`
    grid-template-rows: repeat(5, 1fr);
`;

const OtherAccidentGrid = styled(ColumnGrid)`
    grid-template-rows: repeat(4, 1fr);
`;

const PremiumGrid = styled(ColumnGrid)`
    grid-template-rows: repeat(2, 1fr);
`;

const TierCol = styled.div<WithIndex>`
    padding: 8px;
    min-height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background-color: ${(props) => (props.index % 2 === 0 ? "#f3f6f9" : "white")};
`;

const FullWidthRowHeader = styled.div`
    padding: 8px;
    font-weight: 700;
    display: flex;
    text-align: left;
    align-items: center;
    border: 1px solid;
    color: black;
    background-color: #f3f6f9;
    border-color: #e5eaf2;

    @media ${devices.minDesktop} {
        font-size: ${(props) => props.theme.font.size.h5};
    }

    @media ${devices.desktop} {
        font-size: ${(props) => props.theme.font.size.h6};
    }
`;

type WithIndex = {
    index: number;
};

const ColTitle = styled.span<WithIndex>`
    padding: 8px;
    text-align: left;
    background-color: ${(props) => (props.index % 2 === 0 ? "#f3f6f9" : "white")};
    color: ${(props) => props.theme.colors.grey8};

    sup a {
        text-decoration: none;
    }
`;

const Col = styled.div<WithIndex>`
    padding: 8px;
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.index % 2 === 0 ? "#f3f6f9" : "white")};

    justify-content: center;
`;

export type LoyaltyCoverageOptions = {
    benefits: never[];
    premiums: {
        tier: string;
        individual: number;
        family: number;
    }[];
};

export type CoverageSelection = {
    coverageTier: string;
    coverageType: CoverageTypes;
    coveragePremium: number;
    coverageAmount: number;
};

export type ChooseCoverageTierProps = {
    content: QuoteResultsContent;
    placeholderValues: ReplacedContent;
    coverageAmountOptions: BenefitOption[];
    selectedCoverage: CoverageSelection;
    hasError?: boolean | any;
    onChange?: (selectedCoverage: CoverageSelection) => void;
    onBlur?: () => void;
};

const ChooseCoverageTier = ({
    content,
    placeholderValues,
    coverageAmountOptions,
    selectedCoverage,
    hasError = false,
    onChange = noop,
    onBlur = noop,
}: ChooseCoverageTierProps) => {
    const tierOptions = coverageAmountOptions.map((option) => option.tier);

    const defaultSingleMobileOption = useMemo(() => {
        if (selectedCoverage?.coverageTier) {
            return coverageAmountOptions.filter((option) => option.tier === selectedCoverage.coverageTier);
        } else {
            return [coverageAmountOptions[0]];
        }
    }, [selectedCoverage, coverageAmountOptions]);

    const setSelectedMobileTierSegment = (selectedMobileTierSegment: string) => {
        const selectedCoverageAmountOption = coverageAmountOptions.filter(
            (option) => option.tier === selectedMobileTierSegment
        );
        setFilteredCoverageAmountOptions(selectedCoverageAmountOption);
    };

    const onResize = useCallback(
        (target: HTMLDivElement) => {
            if (!window.matchMedia(devices.minSmall)?.matches) {
                setFilteredCoverageAmountOptions(defaultSingleMobileOption);
            } else {
                setFilteredCoverageAmountOptions(coverageAmountOptions);
            }
        },
        [defaultSingleMobileOption, coverageAmountOptions]
    );

    const resizeRef = useResizeObserver(onResize);

    const [filteredCoverageAmountOptions, setFilteredCoverageAmountOptions] = useState(() => {
        const isSmallWidth = !window.matchMedia(devices.minSmall)?.matches;
        return isSmallWidth ? defaultSingleMobileOption : coverageAmountOptions;
    });

    // FUTURE: Avoid using DOM to hold state, the options are already present in the state
    const nativeOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const coverageTier = event.target.getAttribute("data-coverage-tier")!;
        const coverageType = event.target.getAttribute("data-coverage-type") as CoverageTypes;
        const coverageAmount = coverageAmountOptions.find((option) => option.tier === coverageTier)!.common;

        if (!onChange) {
            return;
        }
        onChange({ coverageTier, coverageType, coveragePremium: parseFloat(event.target.value), coverageAmount });
    };

    const travelAccidentRowHeaders = [
        content.benefitAirlineLabelHtml,
        content.benefitCommonCarrierLabelHtml,
        content.benefitHospitalLabelHtml,
        content.benefitAtHomeRecuperationLabelHtml,
        content.benefitEmergencyLabelHtml,
    ];
    const otherAccidentRowHeaders = [
        content.benefitLossOfLifeLabelHtml,
        content.benefitHospitalLabelHtml,
        content.benefitAtHomeRecuperationLabelHtml,
        content.benefitEmergencyLabelHtml,
    ];
    const premiumRowHeaders = [content.individualPlanLabelHtml, content.familyPlanLabelHtml];

    return (
        <OuterContainer ref={resizeRef} role="table">
            <Title>{contentReplacer(placeholderValues, content.quoteSummaryGreetingTemplate)}</Title>
            <DesktopHeader>
                <Grid tierCount={tierOptions.length}>
                    <TierCol index={1} />
                    {tierOptions.map((tier, index) => (
                        <TierCol index={index} key={`${tier}_${index}`} role="columnheader">
                            {tier}
                        </TierCol>
                    ))}
                </Grid>
            </DesktopHeader>
            <MobileHeader>
                <Segment
                    title="Select a Tier"
                    options={tierOptions}
                    onChange={(option) => setSelectedMobileTierSegment(option)}
                    value={filteredCoverageAmountOptions[0].tier ?? undefined}
                />
            </MobileHeader>
            <FullWidthRowHeader>{content.coverageForTravelAccidentsSectionHeader}</FullWidthRowHeader>
            <TravelAccidentGrid tierCount={tierOptions.length} role="rowgroup">
                {travelAccidentRowHeaders.map((header, row) => (
                    <ColTitle index={1} key={`header-${row}`} {...setRawHtml(header)} />
                ))}

                {filteredCoverageAmountOptions.flatMap((option, column) =>
                    [option.airline, option.common, option.hospital, option.hospital, option.emergencyRoom].map(
                        (figure, row) => (
                            <Col index={column} key={`${option.tier}_travelAccident_${row}`}>
                                {formatUSCurrency(figure, true)}
                            </Col>
                        )
                    )
                )}
            </TravelAccidentGrid>
            <FullWidthRowHeader>{content.coverageForOtherAccidentSectionHeader}</FullWidthRowHeader>
            <OtherAccidentGrid tierCount={tierOptions.length} role="rowgroup">
                {otherAccidentRowHeaders.map((header, row) => (
                    <ColTitle index={1} key={`header-${row}`} {...setRawHtml(header)} />
                ))}

                {filteredCoverageAmountOptions.flatMap((option, column) =>
                    [option.lossOfLife, option.allAccident, option.allAccident, option.allAccident].map((figure, row) => (
                        <Col index={column} key={`${option.tier}_otherAccident_${row}`}>
                            {formatUSCurrency(figure, true)}
                        </Col>
                    ))
                )}
            </OtherAccidentGrid>
            <FullWidthRowHeader>{content.premiumsSectionHeader}</FullWidthRowHeader>
            <PremiumGrid tierCount={tierOptions.length} role="rowgroup">
                {premiumRowHeaders.map((header, row) => (
                    <ColTitle index={1} key={`header-${row}`} {...setRawHtml(header)} />
                ))}

                {filteredCoverageAmountOptions.flatMap((option, index) =>
                    [
                        { plan: "Individual", value: option.individual },
                        { plan: "Family", value: option.family },
                    ].map((planOption) => (
                        <Col index={index} key={`${option.tier}_option_${planOption.plan}`}>
                            <Radio
                                id={`${option.tier.toLowerCase()}Tier${planOption.plan}Plan`}
                                name="coverageOptionsGroup"
                                value={planOption.value}
                                data-coverage-tier={option.tier}
                                data-coverage-type={planOption.plan}
                                label={formatUSCurrency(planOption.value, true)}
                                onChange={nativeOnChange}
                                onBlur={onBlur}
                                checked={
                                    option.tier === selectedCoverage?.coverageTier &&
                                    planOption.plan === selectedCoverage?.coverageType
                                }
                            />
                        </Col>
                    ))
                )}
            </PremiumGrid>
        </OuterContainer>
    );
};

export default ChooseCoverageTier;
