import styled from "styled-components";
import { devices } from "constants/breakpoints";

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.background};

    @media ${devices.minTablet} {
        padding: 30px;
    }

    @media ${devices.tablet} {
        padding: 14px;
    }
`;

const PageDetails = ({ children }) => {
    return <PageWrapper>{children}</PageWrapper>;
};

export default PageDetails;
