import { z } from "zod";

export type RecoveryStep = z.infer<typeof RecoveryStep>;
export const RecoveryStep = z.union([
    z.literal("FETCH_PAYMENT"),
    z.literal("FAST_SUBMIT"),
    z.literal("FAST_UW"),
    z.literal("DOC_PROCESS"),
    z.literal("MANUAL"),
    z.literal("SUBMIT"),
    z.literal("COVERAGE_CHECK"),
]);

export type ServiceErrorResponse = z.infer<typeof ServiceErrorResponse>;
export const ServiceErrorResponse = z.object({
    status: z.number(),
    title: z.string(),
    detail: z.union([z.string(), z.undefined()]).optional(),
});

export type GenderEnum = z.infer<typeof GenderEnum>;
export const GenderEnum = z.union([z.literal("M"), z.literal("F")]);

export type AutoRecoveryRequest = z.infer<typeof AutoRecoveryRequest>;
export const AutoRecoveryRequest = z.object({
    daysBack: z.number(),
    productCodes: z.array(z.union([z.literal("MLTA"), z.literal("DMTERM"), z.literal("GIWL")])),
    alertEmails: z.union([z.array(z.string()), z.undefined()]).optional(),
});

export type SecondaryAddressee = z.infer<typeof SecondaryAddressee>;
export const SecondaryAddressee = z.object({
    firstName: z.string(),
    lastName: z.string(),
    middleInitial: z.union([z.string(), z.undefined()]).optional(),
    suffix: z
        .union([
            z.literal("Jr"),
            z.literal("Sr"),
            z.literal("II"),
            z.literal("III"),
            z.literal("IV"),
            z.literal("PhD"),
            z.literal("MD"),
            z.literal("ESQ"),
            z.literal("DDS"),
            z.literal("V"),
            z.literal("VI"),
            z.literal("VII"),
            z.literal("VIII"),
            z.literal("DVM"),
            z.literal("1st"),
            z.literal("2nd"),
            z.literal("3rd"),
            z.literal("4th"),
            z.literal("5th"),
            z.literal("6th"),
            z.literal("7th"),
            z.literal("8th"),
            z.literal("9th"),
            z.literal("10th"),
            z.literal("11th"),
            z.literal("12th"),
            z.literal("13th"),
            z.literal("14th"),
            z.literal("15th"),
            z.undefined(),
        ])
        .optional(),
    phone: z.union([z.string(), z.undefined()]).optional(),
    address: z.object({
        addressLine1: z.string(),
        addressLine2: z.union([z.string(), z.undefined()]).optional(),
        addressLine3: z.union([z.string(), z.undefined()]).optional(),
        city: z.string(),
        state: z.union([
            z.literal("AL"),
            z.literal("AK"),
            z.literal("AZ"),
            z.literal("AR"),
            z.literal("CA"),
            z.literal("CO"),
            z.literal("CT"),
            z.literal("DE"),
            z.literal("DC"),
            z.literal("FL"),
            z.literal("GA"),
            z.literal("HI"),
            z.literal("ID"),
            z.literal("IL"),
            z.literal("IN"),
            z.literal("IA"),
            z.literal("KS"),
            z.literal("KY"),
            z.literal("LA"),
            z.literal("ME"),
            z.literal("MD"),
            z.literal("MA"),
            z.literal("MI"),
            z.literal("MN"),
            z.literal("MS"),
            z.literal("MO"),
            z.literal("MT"),
            z.literal("NE"),
            z.literal("NV"),
            z.literal("NH"),
            z.literal("NJ"),
            z.literal("NM"),
            z.literal("NY"),
            z.literal("NC"),
            z.literal("ND"),
            z.literal("OH"),
            z.literal("OK"),
            z.literal("OR"),
            z.literal("PA"),
            z.literal("RI"),
            z.literal("SC"),
            z.literal("SD"),
            z.literal("TN"),
            z.literal("TX"),
            z.literal("UT"),
            z.literal("VT"),
            z.literal("VA"),
            z.literal("WA"),
            z.literal("WV"),
            z.literal("WI"),
            z.literal("WY"),
        ]),
        zipCode: z.string(),
        country: z.union([z.string(), z.undefined()]).optional(),
    }),
});

export type finderNumber = z.infer<typeof finderNumber>;
export const finderNumber = z.object({
    finderNumber: z.string(),
});

export type Property = z.infer<typeof Property>;
export const Property = z.object({
    key: z.string().optional(),
    value: z.string().optional(),
});

export type Beneficiary = z.infer<typeof Beneficiary>;
export const Beneficiary = z.object({
    firstName: z.string(),
    middleInitial: z.union([z.string(), z.undefined()]).optional(),
    lastName: z.string(),
    percentage: z.number(),
    relationship: z.union([
        z.literal("Aunt"),
        z.literal("BusinessAssociate"),
        z.literal("BusinessPartner"),
        z.literal("Child"),
        z.literal("Cousin"),
        z.literal("DomesticPartner"),
        z.literal("Father"),
        z.literal("Fiancee"),
        z.literal("FormerSpouse"),
        z.literal("Friend"),
        z.literal("Grandchild"),
        z.literal("Mother"),
        z.literal("Nephew"),
        z.literal("Niece"),
        z.literal("NonSpouse"),
        z.literal("Sibling"),
        z.literal("Spouse"),
        z.literal("Uncle"),
    ]),
    beneficiaryType: z.union([z.literal("primary"), z.literal("contingent")]),
});

export type AllowedStates = z.infer<typeof AllowedStates>;
export const AllowedStates = z.union([
    z.literal("AL"),
    z.literal("AK"),
    z.literal("AZ"),
    z.literal("AR"),
    z.literal("CA"),
    z.literal("CO"),
    z.literal("CT"),
    z.literal("DE"),
    z.literal("DC"),
    z.literal("FL"),
    z.literal("GA"),
    z.literal("HI"),
    z.literal("ID"),
    z.literal("IL"),
    z.literal("IN"),
    z.literal("IA"),
    z.literal("KS"),
    z.literal("KY"),
    z.literal("LA"),
    z.literal("ME"),
    z.literal("MD"),
    z.literal("MA"),
    z.literal("MI"),
    z.literal("MN"),
    z.literal("MS"),
    z.literal("MO"),
    z.literal("MT"),
    z.literal("NE"),
    z.literal("NV"),
    z.literal("NH"),
    z.literal("NJ"),
    z.literal("NM"),
    z.literal("NY"),
    z.literal("NC"),
    z.literal("ND"),
    z.literal("OH"),
    z.literal("OK"),
    z.literal("OR"),
    z.literal("PA"),
    z.literal("RI"),
    z.literal("SC"),
    z.literal("SD"),
    z.literal("TN"),
    z.literal("TX"),
    z.literal("UT"),
    z.literal("VT"),
    z.literal("VA"),
    z.literal("WA"),
    z.literal("WV"),
    z.literal("WI"),
    z.literal("WY"),
]);

export type FindOfferSuccessResponse = z.infer<typeof FindOfferSuccessResponse>;
export const FindOfferSuccessResponse = z.object({
    planCode: z.union([z.string(), z.undefined()]).optional(),
    offer: z.union([z.string(), z.undefined()]).optional(),
    firstName: z.union([z.string(), z.undefined()]).optional(),
    lastName: z.union([z.string(), z.undefined()]).optional(),
    memberOfferAvailable: z.boolean(),
    spouseOfferAvailable: z.boolean(),
    formNumber: z.string(),
    keycode: z.union([z.string(), z.undefined()]).optional(),
    aaaMemberNumber: z.union([z.string(), z.undefined()]).optional(),
    memberLoyaltyYears: z.union([z.number(), z.undefined()]).optional(),
    memberJoinDate: z.union([z.string(), z.null(), z.undefined()]).optional(),
    gender: z.union([GenderEnum, z.undefined()]).optional(),
    finderNumber: z.union([z.string(), z.undefined()]).optional(),
});

export type Application = z.infer<typeof Application>;
export const Application = z.object({
    signedTimestamp: z.string(),
    applicationSubmissionTimestamp: z.string(),
    faceAmount: z.number(),
    applicationID: z.union([z.string(), z.undefined()]).optional(),
    policyNumber: z.string(),
    hannoverID: z.string(),
    paymentToken: z.string(),
    modalPremiumAmount: z.number(),
    acceptanceConfirmed: z.boolean(),
    replacementIndicator: z.boolean(),
    replacementCompany: z.union([z.string(), z.undefined()]).optional(),
    replacementAccount: z.union([z.string(), z.undefined()]).optional(),
    offerPurl: z.union([z.string(), z.undefined()]).optional(),
    termPeriod: z.union([z.number(), z.undefined()]).optional(),
    applicant: z.object({
        firstName: z.string(),
        middleInitial: z.union([z.string(), z.undefined()]).optional(),
        lastName: z.string(),
        birthDate: z.string(),
        phone: z.string(),
        phoneType: z.union([z.literal("Home"), z.literal("Business"), z.literal("Business Fax"), z.literal("Mobile")]),
        usCitizen: z.union([z.boolean(), z.undefined()]).optional(),
        ssn: z.union([z.string(), z.undefined()]).optional(),
        address: z.object({
            addressLine1: z.string(),
            addressLine2: z.union([z.string(), z.undefined()]).optional(),
            addressLine3: z.union([z.string(), z.undefined()]).optional(),
            city: z.string(),
            state: AllowedStates,
            zipCode: z.string(),
            country: z.string(),
        }),
        weight: z.number(),
        gender: GenderEnum,
        aaaMember: z.boolean(),
        aaaMemberNumber: z.union([z.string(), z.undefined()]).optional(),
        formNumber: z.union([z.string(), z.undefined()]).optional(),
        keyCode: z.string(),
        email: z.string(),
        height: z.number(),
        nicotineUse: z.boolean(),
        treatment: z.boolean(),
        diagnosis: z.boolean(),
        employments: z.union([z.array(z.string()), z.undefined()]).optional(),
    }),
    hasSecondaryAddressee: z.boolean(),
    secondaryAddressee: z.union([SecondaryAddressee, z.undefined()]).optional(),
    beneficiaries: z.array(Beneficiary),
    additionalData: z.union([z.array(Property), z.undefined()]).optional(),
    campaign: z.object({
        campaignType: z.string(),
        productCode: z.string(),
        clubCode: z.string(),
        leadSource: z.union([z.string(), z.undefined()]).optional(),
        campaignName: z.string(),
        campaignCode: z.string(),
        offer: z.array(z.number()),
    }),
});

export type CoverageType = z.infer<typeof CoverageType>;
export const CoverageType = z.union([z.literal("Family"), z.literal("Individual")]);

export type DirectTermIdentityResponse = z.infer<typeof DirectTermIdentityResponse>;
export const DirectTermIdentityResponse = z.object({
    policyNumber: z.string(),
    hannoverId: z.string(),
    identityCheckStatus: z.union([z.literal("approved"), z.literal("declined")]),
});

export type DirectTermSubmissionResponse = z.infer<typeof DirectTermSubmissionResponse>;
export const DirectTermSubmissionResponse = z.object({
    policyStatus: z.union([
        z.literal("approved"),
        z.literal("declined"),
        z.literal("refertounderwriter"),
        z.literal("alreadyused"),
    ]),
});

export type GIWL_Application = z.infer<typeof GIWL_Application>;
export const GIWL_Application = z.object({
    objectType: z.union([z.string(), z.undefined()]).optional(),
    signedTimestamp: z.string(),
    paymentRefId: z.string(),
    applicationSubmissionTimestamp: z.string(),
    faceAmount: z.number(),
    applicationID: z.union([z.string(), z.undefined()]).optional(),
    policyNumber: z.union([z.string(), z.undefined()]).optional(),
    isOverCovered: z.union([z.boolean(), z.undefined()]).optional(),
    isInstantIdCheckSuccessful: z.union([z.boolean(), z.undefined()]).optional(),
    paymentToken: z.string(),
    modalPremiumAmount: z.number(),
    applicant: z.object({
        firstName: z.string(),
        middleInitial: z.union([z.string(), z.undefined()]).optional(),
        email: z.union([z.string(), z.undefined()]).optional(),
        lastName: z.string(),
        suffix: z
            .union([
                z.literal("Jr"),
                z.literal("Sr"),
                z.literal("II"),
                z.literal("III"),
                z.literal("IV"),
                z.literal("PhD"),
                z.literal("MD"),
                z.literal("ESQ"),
                z.literal("DDS"),
                z.literal("V"),
                z.literal("VI"),
                z.literal("VII"),
                z.literal("VIII"),
                z.literal("DVM"),
                z.literal("1st"),
                z.literal("2nd"),
                z.literal("3rd"),
                z.literal("4th"),
                z.literal("5th"),
                z.literal("6th"),
                z.literal("7th"),
                z.literal("8th"),
                z.literal("9th"),
                z.literal("10th"),
                z.literal("11th"),
                z.literal("12th"),
                z.literal("13th"),
                z.literal("14th"),
                z.literal("15th"),
                z.undefined(),
            ])
            .optional(),
        birthDate: z.string(),
        phone: z.string(),
        phoneType: z.union([z.literal("Home"), z.literal("Business"), z.literal("Business Fax"), z.literal("Mobile")]),
        aaaMember: z.boolean(),
        aaaMemberNumber: z.union([z.string(), z.undefined()]).optional(),
        address: z.object({
            addressLine1: z.string(),
            addressLine2: z.union([z.string(), z.undefined()]).optional(),
            addressLine3: z.union([z.string(), z.undefined()]).optional(),
            city: z.string(),
            state: AllowedStates,
            zipCode: z.string(),
            country: z.string(),
        }),
        gender: GenderEnum,
        keyCode: z.string(),
    }),
    hasSecondaryAddressee: z.boolean(),
    secondaryAddressee: z.union([SecondaryAddressee, z.undefined()]).optional(),
    replacementIndicator: z.boolean(),
    replacementCompany: z.union([z.string(), z.undefined()]).optional(),
    replacementAccount: z.union([z.string(), z.undefined()]).optional(),
    beneficiaries: z.array(Beneficiary),
    additionalData: z.union([z.array(Property), z.undefined()]).optional(),
    campaign: z.object({
        campaignType: z.string(),
        productCode: z.literal("GIWL"),
        clubCode: z.string(),
        leadSource: z.union([z.string(), z.undefined()]).optional(),
        campaignName: z.string(),
        campaignCode: z.string(),
    }),
});

export type GIWL_SubmissionResponse = z.infer<typeof GIWL_SubmissionResponse>;
export const GIWL_SubmissionResponse = z.object({
    policyStatus: z.union([z.literal("approved"), z.literal("declined"), z.literal("incomplete"), z.literal("exception")]),
    policyNumber: z.union([z.string(), z.undefined()]).optional(),
});

export type Tiers = z.infer<typeof Tiers>;
export const Tiers = z.union([
    z.literal("Value"),
    z.literal("Economy"),
    z.literal("Deluxe"),
    z.literal("Ultra"),
    z.literal("Superior"),
    z.literal("Premier"),
]);

export type MLTA_Application = z.infer<typeof MLTA_Application>;
export const MLTA_Application = z.object({
    objectType: z.union([z.string(), z.undefined()]).optional(),
    signedTimestamp: z.string(),
    applicationSubmissionTimestamp: z.string(),
    faceAmount: z.number(),
    applicationID: z.union([z.string(), z.undefined()]).optional(),
    policyNumber: z.string(),
    paymentToken: z.string(),
    modalPremiumAmount: z.number(),
    acceptanceConfirmed: z.boolean(),
    offerPurl: z.union([z.string(), z.undefined()]).optional(),
    tiers: z.array(Tiers),
    tierSelection: Tiers,
    coverageTypeSelection: CoverageType,
    applicant: z.object({
        firstName: z.string(),
        middleInitial: z.union([z.string(), z.undefined()]).optional(),
        lastName: z.string(),
        birthDate: z.string(),
        phone: z.string(),
        phoneType: z.union([z.literal("Home"), z.literal("Business"), z.literal("Business Fax"), z.literal("Mobile")]),
        usCitizen: z.union([z.boolean(), z.undefined()]).optional(),
        ssn: z.union([z.string(), z.undefined()]).optional(),
        address: z.object({
            addressLine1: z.string(),
            addressLine2: z.union([z.string(), z.undefined()]).optional(),
            addressLine3: z.union([z.string(), z.undefined()]).optional(),
            city: z.string(),
            state: AllowedStates,
            zipCode: z.string(),
            country: z.string(),
        }),
        gender: GenderEnum,
        aaaMemberNumber: z.string(),
        memberJoinDate: z.union([z.string(), z.null(), z.undefined()]).optional(),
        membershipLevel: z.number(),
        formNumber: z.union([z.string(), z.undefined()]).optional(),
        keyCode: z.string(),
        email: z.string(),
        employments: z.union([z.array(z.string()), z.undefined()]).optional(),
    }),
    hasSecondaryAddressee: z.boolean(),
    secondaryAddressee: z.union([SecondaryAddressee, z.undefined()]).optional(),
    beneficiaries: z.array(Beneficiary),
    additionalData: z.union([z.array(Property), z.undefined()]).optional(),
    campaign: z.object({
        campaignType: z.string(),
        productCode: z.union([z.literal("MLTA"), z.literal("ACLTA")]),
        clubCode: z.string(),
        leadSource: z.union([z.string(), z.undefined()]).optional(),
        campaignName: z.string(),
        campaignCode: z.string(),
    }),
});

export type MLTA_IsEligibleApplication = z.infer<typeof MLTA_IsEligibleApplication>;
export const MLTA_IsEligibleApplication = z.object({
    policyNumber: z.string().optional(),
});

export type MLTA_IsEligibleApplicationResponse = z.infer<typeof MLTA_IsEligibleApplicationResponse>;
export const MLTA_IsEligibleApplicationResponse = z.object({
    policyNumber: z.string(),
});

export type MLTA_SubmissionResponse = z.infer<typeof MLTA_SubmissionResponse>;
export const MLTA_SubmissionResponse = z.object({
    policyStatus: z.union([z.literal("approved"), z.literal("alreadyused")]),
});

export type Person = z.infer<typeof Person>;
export const Person = z.object({
    firstName: z.string(),
    middleInitial: z.union([z.string(), z.undefined()]).optional(),
    lastName: z.string(),
    birthDate: z.string(),
    phone: z.string(),
    ssn: z.union([z.string(), z.undefined()]).optional(),
    policyNumber: z.union([z.string(), z.undefined()]).optional(),
    hannoverID: z.union([z.string(), z.undefined()]).optional(),
    address: z.object({
        addressLine1: z.string(),
        addressLine2: z.union([z.string(), z.undefined()]).optional(),
        addressLine3: z.union([z.string(), z.undefined()]).optional(),
        city: z.string(),
        state: AllowedStates,
        zipCode: z.string(),
        country: z.string(),
    }),
    gender: GenderEnum,
    email: z.string(),
});

export type post_Submit = typeof post_Submit;
export const post_Submit = {
    method: z.literal("POST"),
    path: z.literal("/submit"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: Application,
    }),
    response: DirectTermSubmissionResponse,
};

export type post_SubmitMLTAApplication = typeof post_SubmitMLTAApplication;
export const post_SubmitMLTAApplication = {
    method: z.literal("POST"),
    path: z.literal("/submitMLTAApplication"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: MLTA_Application,
    }),
    response: MLTA_SubmissionResponse,
};

export type post_SubmitGIWLApplication = typeof post_SubmitGIWLApplication;
export const post_SubmitGIWLApplication = {
    method: z.literal("POST"),
    path: z.literal("/submitGIWLApplication"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: GIWL_Application,
    }),
    response: GIWL_SubmissionResponse,
};

export type post_Recover = typeof post_Recover;
export const post_Recover = {
    method: z.literal("POST"),
    path: z.literal("/recover"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
            recoveryStep: z.union([
                z.literal("FETCH_PAYMENT"),
                z.literal("FAST_SUBMIT"),
                z.literal("FAST_UW"),
                z.literal("DOC_PROCESS"),
                z.literal("MANUAL"),
                z.literal("SUBMIT"),
                z.literal("COVERAGE_CHECK"),
                z.undefined(),
            ]),
        }),
        body: Application,
    }),
    response: DirectTermSubmissionResponse,
};

export type post_Recovermlta = typeof post_Recovermlta;
export const post_Recovermlta = {
    method: z.literal("POST"),
    path: z.literal("/recovermlta"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
            recoveryStep: z.union([
                z.literal("FETCH_PAYMENT"),
                z.literal("FAST_SUBMIT"),
                z.literal("FAST_UW"),
                z.literal("DOC_PROCESS"),
                z.literal("MANUAL"),
                z.literal("SUBMIT"),
                z.literal("COVERAGE_CHECK"),
                z.undefined(),
            ]),
        }),
        body: MLTA_Application,
    }),
    response: MLTA_SubmissionResponse,
};

export type post_AutoRecovery = typeof post_AutoRecovery;
export const post_AutoRecovery = {
    method: z.literal("POST"),
    path: z.literal("/autoRecovery"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        body: AutoRecoveryRequest,
    }),
    response: z.boolean(),
};

export type post_CheckIdentity = typeof post_CheckIdentity;
export const post_CheckIdentity = {
    method: z.literal("POST"),
    path: z.literal("/checkIdentity"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: Person,
    }),
    response: DirectTermIdentityResponse,
};

export type post_FindOffer = typeof post_FindOffer;
export const post_FindOffer = {
    method: z.literal("POST"),
    path: z.literal("/findOffer"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: finderNumber,
    }),
    response: FindOfferSuccessResponse,
};

export type post_IsEligibleApplication = typeof post_IsEligibleApplication;
export const post_IsEligibleApplication = {
    method: z.literal("POST"),
    path: z.literal("/isEligibleApplication"),
    requestFormat: z.literal("json"),
    parameters: z.object({
        header: z.object({
            trackingID: z.string(),
            channelID: z.literal("web"),
        }),
        body: MLTA_IsEligibleApplication,
    }),
    response: MLTA_IsEligibleApplicationResponse,
};

// <EndpointByMethod>
export const EndpointByMethod = {
    post: {
        "/submit": post_Submit,
        "/submitMLTAApplication": post_SubmitMLTAApplication,
        "/submitGIWLApplication": post_SubmitGIWLApplication,
        "/recover": post_Recover,
        "/recovermlta": post_Recovermlta,
        "/autoRecovery": post_AutoRecovery,
        "/checkIdentity": post_CheckIdentity,
        "/findOffer": post_FindOffer,
        "/isEligibleApplication": post_IsEligibleApplication,
    },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
    body?: unknown;
    query?: Record<string, unknown>;
    header?: Record<string, unknown>;
    path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
    parameters?: EndpointParameters | undefined;
    response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
    operationId: string;
    method: Method;
    path: string;
    requestFormat: RequestFormat;
    parameters?: TConfig["parameters"];
    meta: {
        alias: string;
        hasParameters: boolean;
        areParametersRequired: boolean;
    };
    response: TConfig["response"];
};

type Fetcher = (method: Method, url: string, parameters?: EndpointParameters | undefined) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
    [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
    baseUrl: string = "";

    constructor(public fetcher: Fetcher) {}

    setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;
        return this;
    }

    // <ApiClient.post>
    post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
        path: Path,
        ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
    ): Promise<z.infer<TEndpoint["response"]>> {
        return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
    }
    // </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
    return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
