import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { Button } from "components/inputs";
import { useIdleTimer, useFormState } from "hooks";
import { formatDuration } from "utils/formatDuration";
import { DEFAULT_TIMEOUT, DEFAULT_PROMPT_BEFORE_IDLE } from "constants/sessionTimeouts";

const CenteredContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`;

const PopupContainer = styled.div`
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.grey3};
    border: 1px solid ${(props) => props.theme.colors.grey5};
    box-shadow: 1px 1px 5px 0 ${(props) => props.theme.colors.grey4};

    @media ${devices.minTablet} {
        width: 750px;
        padding: ${(props) => props.theme.spacing.padding.large};
    }

    @media ${devices.tablet} {
        width: 80vw;
        padding: ${(props) => props.theme.spacing.padding.medium};
    }
`;

const Heading = styled.h2`
    color: ${(props) => props.theme.colors.grey8};
    font-weight: bold;
    text-align: center;

    @media ${devices.minMobile} {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media ${devices.mobile} {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media ${devices.minTablet} {
        font-size: ${(props) => props.theme.font.size.h2};
    }

    @media ${devices.tablet} {
        font-size: ${(props) => props.theme.font.size.h3};
    }
`;

const TimerText = styled.h2`
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.font.size.h2};
    font-weight: bold;

    @media ${devices.minMobile} {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media ${devices.mobile} {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const Backdrop = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: transparent;
`;

const IdleTimerPopup = ({ timeout = DEFAULT_TIMEOUT, promptBeforeIdle = DEFAULT_PROMPT_BEFORE_IDLE }) => {
    const { state } = useFormState();
    const { timeout: localStorageTimeout, promptBeforeIdle: localStoragePromptBeforeIdle } = state?.session;

    if (localStorageTimeout) {
        timeout = localStorageTimeout;
    }

    if (localStoragePromptBeforeIdle) {
        promptBeforeIdle = localStoragePromptBeforeIdle;
    }

    const { open, remaining, handleStillHere } = useIdleTimer({ timeout, promptBeforeIdle });
    const formattedRemainingMinutes = formatDuration(remaining);

    return (
        open && (
            <Backdrop>
                <CenteredContainer>
                    <PopupContainer>
                        <Heading>Your session is about to time out.</Heading>
                        <TimerText>{formattedRemainingMinutes}</TimerText>
                        <Button onClick={handleStillHere} color="primary" label="Click To Continue" />
                    </PopupContainer>
                </CenteredContainer>
            </Backdrop>
        )
    );
};

export default IdleTimerPopup;
