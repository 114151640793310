import styled from "styled-components";
import { devices } from "constants/breakpoints";

const SplitColumnWrapper = styled.div`
    @media ${devices.minTablet} {
        display: grid;
        grid-template-columns: 45% 55%;
        min-height: 550px;
    }

    @media ${devices.tablet} {
        display: flex;
        flex-direction: column;
    }
`;

const SplitColumn = ({ children }) => {
    return <SplitColumnWrapper>{children}</SplitColumnWrapper>;
};

export default SplitColumn;
