import styled from "styled-components";
import { FullPageWithBanner } from "layout/page-templates";
import { usePageTitle, useFormCompletion, useContent, useSendInteraction } from "hooks";
import { H1 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import { ReplacedContent, contentReplacer } from "utils/contentReplacer";
import { DTOPageEnum } from "constants/pageNames";
import { ApprovedContentSchema } from "content/contentSchemas";

const BodyContainer = styled.div`
    p {
        font-size: 18px;
    }
`;

const pageName = DTOPageEnum.enum.approved;
const Approved = () => {
    usePageTitle("Approved | AAA Life");
    useSendInteraction(pageName);

    const [data] = useFormCompletion(pageName, (state) => ({
        name: state?.application?.firstName,
        policy: state?.application?.policyNumber ?? undefined,

        applicationMode: state?.applicationMode,
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state,
    }));

    const { result: content } = useContent({
        targetSchema: ApprovedContentSchema,
        applicationMode: data.applicationMode,
        pageName: "approved",
        clubCode: data.clubCode ?? undefined,
        state: data.state,
    });

    if (!data || !content) {
        return null;
    }

    const contentToReplace: ReplacedContent = {
        name: data.name,
        policy: data.policy,
        productName: content.productName,
    };

    const title = contentReplacer(contentToReplace, content.title);
    const bodyHtml = contentReplacer(contentToReplace, content.bodyHtml);

    return (
        <FullPageWithBanner>
            <H1>{title}</H1>
            <BodyContainer {...setRawHtml(bodyHtml)} />
        </FullPageWithBanner>
    );
};

export default Approved;
