import styled from "styled-components";
import { devices } from "constants/breakpoints";
import Container from "layout/Container";
import { FooterContent } from "content/contentSchemas";
import { setRawHtml } from "content/setRawHtml";

const StyledFooter = styled.footer`
    height: 200px;

    background-color: ${(props) => props.theme.colors.background};
    border-top: 2px solid ${(props) => props.theme.colors.grey5};

    font-size: ${(props) => props.theme.font.size.text};

    @media ${devices.minTablet} {
        padding: ${(props) => props.theme.spacing.padding.large};
    }

    @media ${devices.tablet} {
        padding: ${(props) => props.theme.spacing.padding.small};
    }
`;

const LinksContainer = styled.div`
    display: flex;

    @media ${devices.tablet} {
        flex-direction: column;
        line-height: 28px;
    }
`;

const Link = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue};

    @media ${devices.tablet} {
        padding-top: ${(props) => props.theme.spacing.padding.xs};
        padding-bottom: ${(props) => props.theme.spacing.padding.xs};
        font-weight: ${(props) => props.theme.font.weight.bold};
    }
`;

const Divider = styled.span`
    color: ${(props) => props.theme.colors.grey4};

    @media ${devices.minTablet} {
        padding-left: ${(props) => props.theme.spacing.padding.large};
        padding-right: ${(props) => props.theme.spacing.padding.large};
    }

    @media ${devices.tablet} {
        display: none;
    }
`;

const Content = styled.div`
    align-items: center;
    display: flex;
`;

const FootnoteList = styled.ol`
    flex: 1;

    counter-reset: item;
    margin: 1.5em 0;
    padding: 0;
    list-style: none;

    li {
        display: block;
    }

    li:before {
        content: counter(item) "";
        counter-increment: item;
        display: inline-block;
        text-align: left;
        padding-right: 0.5em;
        vertical-align: super;
        font-size: xx-small;
    }
`;

const FooterTextDetail = styled.div`
    margin-right: ${(props) => props.theme.spacing.padding.large};
`;

type FooterProps = {
    content: FooterContent;
};

const Footer = ({ content }: FooterProps) => {
    const footnotes = [content.footnote1, content.footnote2, content.footnote3, content.footnote4, content.footnote5].filter(
        (item) => !!item
    );

    return (
        <StyledFooter>
            <Container>
                <LinksContainer>
                    <Link href="https://www.aaalife.com/disclosures" target="_blank" rel="noreferrer">
                        Disclosures
                    </Link>
                    <Divider>|</Divider>
                    <Link href="https://www.aaalife.com/" target="_blank" rel="noreferrer">
                        AAALife.com
                    </Link>
                    <Divider>|</Divider>
                    <Link href="https://www.aaalife.com/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </Link>
                    <Divider>|</Divider>
                    <Link href="https://www.aaalife.com/terms-and-conditions" target="_blank" rel="noreferrer">
                        Terms and Conditions
                    </Link>
                    <Divider>|</Divider>
                    <Link href="https://www.aaalife.com/contact-us" target="_blank" rel="noreferrer">
                        Contact Us
                    </Link>
                </LinksContainer>

                <FootnoteList>
                    {footnotes.map((footnote, index) => (
                        <li id={`footnote-${index + 1}`} key={index}>
                            {footnote}
                        </li>
                    ))}
                </FootnoteList>

                <Content>
                    <FooterTextDetail {...setRawHtml(content.footerHtml)} />
                    <a
                        href="https://www.entrust.net/customer/profile.cfm?domain=www.aaalife.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt="Secured By Entrust, SSL (Secure Sockets Layer)."
                            src="https://seal.entrust.net/en/current/entrust_site_seal_large.png"
                        />
                    </a>
                </Content>
            </Container>
        </StyledFooter>
    );
};

export default Footer;
