import { z } from "zod";
import { ApplicationModeEnumSchema, BenefitOptionSchema, QuoteOptionSchema } from "state/ApplicationStateSchema";

const ApplicationStep1Schema = z.object({
    dateOfBirth: z.string().regex(/^[1-2]\d{3}-[0-1][0-9]-[0-3][0-9]$/),
    firstName: z.string().nullish(),
    selectedCoverageAmount: z.number().optional(),
});

export const DirectTermApplicationStep1Schema = ApplicationStep1Schema.merge(
    z.object({
        gender: z.enum(["male", "female"]),
        coverageOptions: z.array(QuoteOptionSchema),
    })
);

export const LoyaltyApplicationStep1Schema = ApplicationStep1Schema.merge(
    z.object({
        coverageOptions: z.array(BenefitOptionSchema),
        // FUTURE: Later remove nullable and opt for undefined
        selectedCoveragePremium: z.number().optional(),
        selectedCoverageTier: z.string().optional(),
        selectedCoverageType: z.enum(["Individual", "Family"]).optional(),
    })
);

export const QuoteResultsStateSchema = z.discriminatedUnion("applicationMode", [
    z.object({
        applicationMode: z.literal(ApplicationModeEnumSchema.enum.directterm),
        application: DirectTermApplicationStep1Schema,
    }),
    z.object({
        applicationMode: z.literal(ApplicationModeEnumSchema.enum.loyalty),
        application: LoyaltyApplicationStep1Schema,
        membershipLength: z.number(),
    }),
]);
