import { useContext } from "react";
import { FormState, FormStateContext } from "providers/FormStateContextProvider";
import { DTOPages } from "constants/pageNames";

/**
 * A hook that when called provides access to the state and dispatch
 * functionality.
 */
export function useFormState(pageName?: DTOPages): FormState {
    return useContext(FormStateContext);
}
