import { z } from "zod";
import { USER_INTERACTION_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import { interaction, post_Interaction } from "./user-interaction-service.openapi.zod";

export const InteractionRequestBodySchema = interaction;
export type InteractionRequestBody = z.infer<typeof interaction>;
type InteractionResponseBody = z.infer<typeof post_Interaction.response>;

export async function sendInteraction(interactionData: InteractionRequestBody): Promise<InteractionResponseBody> {
    const url = `${USER_INTERACTION_SERVICE_BASE_URL}/interaction`;
    const headers = {
        ...createCommonHeaders(window),
        "Content-Type": "application/json",
    };
    const body = JSON.stringify(interactionData);

    const response = await fetch(url, {
        method: "POST",
        body,
        headers,
    });

    if (!response.ok) {
        console.warn(response);
        throw new Error("Failed to send interaction");
    }

    return response.bodyUsed ? post_Interaction.response.parse(await response.json()) : Promise.resolve();
}
