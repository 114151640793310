import { Pages } from "constants/pageNames";
import { useEffect, useState } from "react";
import { ApplicationModeEnum } from "state/ApplicationStateSchema";
import { getContent } from "services/webContentService";
import { AsyncTaskResult, useAsyncTask } from "./useAsyncTask";
import { z } from "zod";
import { validateContent } from "content/contentUtils";

export type UseContentProps<TSchema> = {
    targetSchema: TSchema;
    applicationMode?: ApplicationModeEnum;
    pageName?: Pages;
    state?: string;
    planCode?: string;
    clubCode?: string;
};

export function useContent<T extends z.AnyZodObject>(contentProps: UseContentProps<T>): AsyncTaskResult<z.infer<T>, any> {
    type TResult = z.infer<T>;
    const [promise, setPromise] = useState<Promise<TResult>>(Promise.race([]));
    const { targetSchema, applicationMode = undefined, pageName = "", state = "", planCode = "", clubCode = "" } = contentProps;

    useEffect(() => {
        setPromise(
            getContent(applicationMode, pageName, state, planCode, clubCode)
                .then((data) => validateContent(targetSchema, data))
                .catch((error) => {
                    const searchReference = JSON.stringify({ applicationMode, pageName, state, planCode, clubCode });
                    console.error(`The provided content object is invalid according to schema for search ${searchReference}`);
                    throw error;
                })
        );
    }, [targetSchema, applicationMode, pageName, state, planCode, clubCode]);

    return useAsyncTask(promise);
}
