import React, { forwardRef, Ref } from "react";
import styled from "styled-components";
import { FieldError } from "react-hook-form";

const StyledInput = styled.input<InputStyles>`
    height: 38px;
    font-size: 16px;
    padding: 0 10px;

    border-style: solid;
    border-radius: 3px;
    border-width: ${(props) => (props.hasError ? "2px" : "1px")};
    border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

    &:focus-visible {
        outline: none;
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey7)};
    }
`;

type InputStyles = {
    hasError?: FieldError | boolean;
};

type Props = InputStyles & React.ComponentPropsWithoutRef<"input">;

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text
const InputText = forwardRef<HTMLInputElement, Props>(
    ({ id, name, placeholder, value, onChange, disabled = false, hasError = false, ...rest }, ref: Ref<HTMLInputElement>) => (
        <StyledInput
            id={id}
            name={name}
            data-testid={`test-${name}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            hasError={hasError}
            type="text"
            ref={ref}
            {...rest}
        />
    )
);

export default InputText;
