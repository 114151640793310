import styled from "styled-components";
import { ErrorMessage } from "components/inputs";
import { logErrors } from "utils/logErrors";
import { useEffect, useRef, useState } from "react";
import Loader from "components/loader/Loader";
import { FieldErrors, SubmitErrorHandler, useForm } from "react-hook-form";
import {
    PaymentErrorCallback,
    PaymentGatewayFrame,
    PaymentGatewayFrameElement,
    PaymentSubmissionCallback,
    PaymentValidationErrorCallback,
} from "./PaymentGatewayFrame";
import { ValidatedPayment } from "features/pages/app/payment-info/ValidatedPayment";
import { PaymentInfoContent } from "content/contentSchemas";
import { noop } from "constants/noop";
import { setRawHtml } from "content/setRawHtml";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledFieldSet = styled.fieldset`
    border: none;
`;

const SupplementalAuthorizationText = styled.div`
    padding-left: 37px;
    margin-bottom: 29px;

    * {
        padding: 0;
        margin: 0;
    }
`;

function StyledForm(props: React.ComponentPropsWithoutRef<"form"> & { disabled?: boolean }) {
    return (
        <Form {...props}>
            <StyledFieldSet disabled={props.disabled}>{props.children}</StyledFieldSet>
        </Form>
    );
}

export type PaymentInfoFormDefaultValues = { paymentRefId: string; paymentToken?: string };

type PaymentInfoFormProps = {
    defaultValues: PaymentInfoFormDefaultValues;
    onSubmit: (data: { paymentToken: string }) => void;
    onError: (errors?: FieldErrors<PaymentInfoFormDefaultValues> | string) => void;
    onBusy: (isBusy: boolean) => void;
    content: PaymentInfoContent;
};

const PaymentInfoForm = ({
    defaultValues: { paymentRefId, paymentToken },
    onSubmit,
    onError,
    onBusy,
    content,
}: PaymentInfoFormProps) => {
    const {
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            paymentRefId,
            paymentToken,
        },
    });
    const [editMode, setEditMode] = useState(!paymentToken);
    const [isLoading, setIsLoading] = useState(true);
    const submitterRef = useRef<PaymentGatewayFrameElement>({ submit: noop } as PaymentGatewayFrameElement);

    const isBusy = (editMode && isLoading) || isSubmitting;
    useEffect(() => {
        onBusy(isBusy);
        console.log("Busy " + isBusy);
    }, [onBusy, isBusy]);

    const handleClickEdit = () => {
        setEditMode(true);
    };

    const submissionSucceeded: PaymentSubmissionCallback = (data) => {
        onSubmit({ paymentToken: data.paymentToken });
    };

    const submissionFailed: PaymentErrorCallback = (error) => {
        onError(error);
        setError("root.frame", { type: "submission", message: error });
    };

    const validationFailed: PaymentValidationErrorCallback = () => {
        onError("Validation error");
        setError("root.frame", { type: "validation", message: "Field validation failed." });
    };

    const onErrorWithLogging: SubmitErrorHandler<PaymentInfoFormDefaultValues> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    const onSubmitProxy =
        (editMode && (() => submitterRef.current.submit())) ||
        (paymentToken && (() => onSubmit({ paymentToken }))) ||
        (() => {
            throw new Error("Unexpected state when submitting payment info.");
        });

    function onLoadStart() {
        setIsLoading(true);
    }

    function onLoad() {
        setIsLoading(false);
    }

    return (
        <Container>
            <StyledForm
                id="payment-info-form"
                data-testid="payment-info-form"
                onSubmit={handleSubmit(onSubmitProxy, onErrorWithLogging)}
                disabled={isBusy}
            >
                <>
                    <Loader speedMultiplier={0.5} loading={isLoading && editMode} />
                    <PaymentGatewayFrame
                        ref={submitterRef}
                        paymentRefId={paymentRefId}
                        onSubmissionSucceeded={submissionSucceeded}
                        onSubmissionFailed={submissionFailed}
                        onValidationFailed={validationFailed}
                        onLoadStart={onLoadStart}
                        onLoad={onLoad}
                        style={{ display: isLoading || !editMode ? "none" : "block" }}
                    />
                    <SupplementalAuthorizationText {...setRawHtml(content.supplementalPaymentAuthorizationHtml)} />
                </>

                {!editMode && (
                    <ValidatedPayment
                        onEdit={handleClickEdit}
                        message={content.validatedPaymentMessage}
                        buttonLabel={content.editPaymentButtonLabel}
                    />
                )}

                {Object.keys(errors).length > 0 && (
                    <ErrorMessage text={errors.root?.frame?.type === "submission" ? content.submissionError : undefined} />
                )}
            </StyledForm>
        </Container>
    );
};

export default PaymentInfoForm;
