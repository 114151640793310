import styled, { css } from "styled-components";

const StyledReviewDataRow = styled.div`
    padding: ${(props) => props.theme.spacing.padding.xs} 0;
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
`;

const reviewDataTextStyle = css`
    font-size: ${(props) => props.theme.font.size.text};
    margin: 0;
    color: ${(props) => props.theme.colors.grey7};
`;

type WithIndexedProp = {
    indexed?: boolean;
};

const ReviewDataText = styled.p<WithIndexedProp>`
    ${reviewDataTextStyle}
`;

const ReviewDataTitleWrapper = styled.div`
    width: 60%;
    min-width: 60%;
    display: flex;
`;

const ReviewDataTitle = styled(ReviewDataText)`
    ${(props) => (!props.indexed ? "font-weight: " + props.theme.font.weight.semibold : "")};
`;

const ReviewDataIndex = styled(ReviewDataText)`
    padding-right: ${(props) => props.theme.spacing.padding.xs};
    color: ${(props) => props.theme.colors.text};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`;

const Address = styled.address`
    font-style: normal;
    ${reviewDataTextStyle}
`;

export type ReviewDataProps = {
    index?: number;
    title: JSX.Element | string;
    isAddress?: boolean;
    data: JSX.Element | string;
};

const ReviewData = ({ index, title, isAddress, data }: ReviewDataProps) => (
    <StyledReviewDataRow>
        <ReviewDataTitleWrapper>
            {index && <ReviewDataIndex>{index}.</ReviewDataIndex>}
            <ReviewDataTitle indexed={index !== undefined}>{title}</ReviewDataTitle>
        </ReviewDataTitleWrapper>
        {isAddress === true ? <Address>{data}</Address> : <ReviewDataText>{data}</ReviewDataText>}
    </StyledReviewDataRow>
);

export default ReviewData;
