import React, { forwardRef } from "react";
import { InputTextRestricted } from "components/inputs/restricted/InputTextRestricted";
import { FieldError } from "react-hook-form";

type Props = {
    hasError?: FieldError;
} & React.ComponentPropsWithoutRef<"input">;

export const InputZipCode = forwardRef<HTMLInputElement, Props>((props, ref) => {
    return <InputTextRestricted maxLength={5} allowedCharacterClass="0-9" {...props} ref={ref} />;
});
