import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useLandingPageUrl() {
    const [searchParams] = useSearchParams();

    return useMemo(
        () => ({
            url: window.location.href,
            params: new Map(searchParams),
        }),
        [searchParams]
    );
}
