import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer as useReactIdleTimer } from "react-idle-timer";
import { IdleTimerContext } from "providers/IdleTimerProvider";
import { ALLOWED_PATHNAMES } from "constants/sessionTimeouts";

const storybookUrl = "http://localhost:6006";

// https://idletimer.dev/docs/api/use-idle-timer
export function useIdleTimer({ timeout, promptBeforeIdle }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [status, setStatus] = useState("Active");
    const [remaining, setRemaining] = useState(null);
    const [timeTillPrompt, setTimeTillPrompt] = useState(null);
    const { open, setOpen } = useContext(IdleTimerContext);

    // Check that the pathname is allowed; or that the url is the local storybook development domain
    const shouldRunOnPage = useCallback(() => {
        return ALLOWED_PATHNAMES.some((str) => pathname.includes(str)) || window.location.href.includes(storybookUrl);
    }, [pathname]);

    const onIdle = () => {
        if (shouldRunOnPage()) {
            setStatus("Idle");
            setOpen(false);
            navigate("/session-expired");
        }
    };

    const onActive = () => {
        if (shouldRunOnPage()) {
            setStatus("Active");
            setOpen(false);
        }
    };

    const onPrompt = () => {
        if (shouldRunOnPage()) {
            setStatus("Prompted");
            setOpen(true);
        }
    };

    const handleStillHere = () => {
        activate();
    };

    // Since we have an allowlist of pathnames in which to run the countdown, don't start the timers before checking the page path
    const { start, getRemainingTime, activate } = useReactIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        stopOnIdle: true,
        startOnMount: false,
        startManually: true,
    });

    useEffect(() => {
        if (shouldRunOnPage()) {
            start();
        } else {
            return;
        }
    }, [shouldRunOnPage, start]);

    const countdown = useCallback(() => {
        const remainingSeconds = Math.round(getRemainingTime() / 1000);
        const promptSeconds = promptBeforeIdle / 1000;
        const timeTillPromptSeconds = Math.max(remainingSeconds - promptSeconds, 0);

        setTimeTillPrompt(timeTillPromptSeconds);
        setRemaining(remainingSeconds);
    }, [getRemainingTime, promptBeforeIdle]);

    useEffect(() => {
        let interval;
        if (shouldRunOnPage()) {
            interval = setInterval(() => {
                countdown();
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [shouldRunOnPage, countdown]);

    return { status, open, timeTillPrompt, remaining, handleStillHere };
}
