import { usePageTitle } from "hooks";

const NoMatch = () => {
    usePageTitle("404 | AAA Life");

    return (
        <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
        </main>
    );
};

export default NoMatch;
