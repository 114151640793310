import styled from "styled-components";
import { devices } from "constants/breakpoints";

const Container = styled.div`
    margin: auto;

    @media ${devices.minUltra} {
        max-width: 1470px;
    }

    @media ${devices.minDesktop} and ${devices.ultra} {
        max-width: 1140px;
    }

    @media ${devices.minLaptop} and ${devices.desktop} {
        max-width: 960px;
    }

    @media ${devices.laptop} {
        max-width: 750px;
    }
`;

export default Container;
