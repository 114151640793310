type ErrorType = Record<string, Partial<{ type: string | number; message: string }>> &
    Partial<{ type: string | number; message: string }>;

export const logErrors = (errors: ErrorType | null | undefined | false | "") => {
    if (!errors) {
        return;
    }
    const errorLogs = Object.keys(errors).map((key) => ({
        key: key,
        message: errors[key].message,
        type: errors[key].type,
    }));
    errorLogs.forEach((errorLog) => console.warn(JSON.stringify(errorLog)));
};
