import styled from "styled-components";
import { devices } from "constants/breakpoints";

export const H2 = styled.h2`
    color: ${(props) => props.theme.colors.blue};
    font-size: ${(props) => props.theme.font.size.h4};
    font-weight: bold;
    margin: 0;

    @media ${devices.minTablet} {
        padding-bottom: 30px;
    }

    @media ${devices.tablet} {
        padding-bottom: 14px;
    }
`;
