import { useEffect, useState } from "react";

export type AsyncTaskResult<TResult, TError> = {
    result?: TResult;
    hasCompleted: boolean;
    hasError: boolean;
    error?: TError;
};

export function useAsyncTask<TResult>(initializer: (() => Promise<TResult>) | Promise<TResult>): AsyncTaskResult<TResult, any> {
    const [state, setState] = useState({ hasCompleted: false, hasError: false, result: undefined, error: undefined });

    if (!initializer) {
        throw new Error("No initializer was provided");
    }

    useEffect(() => {
        const promise = typeof initializer === "function" ? initializer() : initializer;

        promise
            .then((promiseResult: any) => {
                setState({ hasCompleted: true, hasError: false, result: promiseResult, error: undefined });
            })
            .catch((promiseError: any) => {
                console.error(promiseError);
                const error = promiseError || new Error("No error was provided");
                setState({ hasCompleted: true, hasError: true, result: undefined, error: error });
            });
    }, [initializer]);

    return state;
}
