import { createRef, forwardRef, useState } from "react";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputText from "../text/InputText";
import { ReactComponent as CalendarIcon } from "assets/images/calendar.svg";

const StyledDatePicker = styled(ReactDatePicker)`
    height: 38px;
    font-size: inherit;
    padding-left: 10px;
    border: none;
`;

const StyledDatePickerWrapper = styled.div`
    display: flex;
    gap: 10px;

    border-style: solid;
    border-radius: 3px;
    border-width: ${(props) => (props.hasError ? "2px" : "1px")};
    padding: ${(props) => (props.hasError ? "0px" : "1px")};
    border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

    &:focus-visible {
        outline: none;
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey7)};
    }
`;

const StyledCalendarButton = styled.button`
    background: inherit;
    border: none;
`;

const StyledDateInputText = styled(InputText)`
    border: none;
    padding: 0px;
`;

/**
 * @type {ReactDatePicker}
 * See https://reactdatepicker.com/
 */
const DatePicker = forwardRef(
    (
        {
            id,
            name,
            onChange,
            dateFormat = "MM/dd/yyyy",
            placeholder = "MM/DD/YYYY",
            hasError = false,
            isDisabled = false,
            isClearable = false,
            select = undefined,
            autocomplete = "off",
            showIcon = true,
            ...rest
        },
        ref
    ) => {
        const [startDate, setStartDate] = useState(select);
        const datepickerRef = ref || createRef();

        const CustomInput = forwardRef(({ value }, inputRef) => {
            const onBlur = (e) => {
                try {
                    if (e.target.value === "") {
                        setStartDate(null);
                        datepickerRef.current.clear();
                        onChange(null);
                        return;
                    }
                    const date = new Date(e.target.value);
                    if (Number.isNaN(date.valueOf())) {
                        console.log(`The date '${e.target.value}' was ignored because it is invalid.`);
                        e.target.value = "";
                        return;
                    }
                    setStartDate(date);
                    onChange(date);
                } catch (error) {
                    console.error(error);
                }
            };
            const onKeyPress = (e) => {
                if (e.key === " ") {
                    datepickerRef.current.setOpen(true);
                    e.preventDefault();
                } else if (!/^[0-9/]$/.test(e.key)) {
                    e.preventDefault();
                }
            };

            return (
                <StyledDateInputText
                    ref={inputRef}
                    placeholder="MM/DD/YYYY"
                    defaultValue={value}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    id={id}
                    name={name}
                    autocomplete={autocomplete}
                />
            );
        });

        const onClick = () => {
            if (datepickerRef?.current /*&& datepickerRef.current.isCalendarOpen*/) {
                datepickerRef.current.setOpen(true);
                datepickerRef.current.cancelFocusInput();
            }
        };

        const onCalendarOpen = () => {
            const dayToFocus = datepickerRef.current.calendar.componentNode.querySelectorAll(
                ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected"
            );
            if (dayToFocus.length > 0) {
                dayToFocus[0].focus();
            }
        };

        return (
            <StyledDatePickerWrapper hasError={hasError}>
                {showIcon && (
                    <StyledCalendarButton onClick={onClick} type="button">
                        <CalendarIcon className="react-datepicker__calendar-icon" />
                    </StyledCalendarButton>
                )}
                <StyledDatePicker
                    id={id}
                    name={name}
                    dateFormat={dateFormat}
                    placeholderText={placeholder}
                    hasError={hasError}
                    disabled={isDisabled}
                    isClearable={isClearable}
                    showPopperArrow={false}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    useShortMonthInDropdown
                    strictParsing={true}
                    ref={datepickerRef}
                    customInput={<CustomInput />}
                    selected={startDate}
                    ariaInvalid={hasError}
                    {...rest}
                    onChange={(date) => {
                        setStartDate(date);
                        onChange(date);
                    }}
                    onCalendarOpen={onCalendarOpen}
                />
            </StyledDatePickerWrapper>
        );
    }
);

export default DatePicker;
