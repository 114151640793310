/**
 *
 * @param {Array} optionsArray
 * @returns
 */
export function createSelectOptions(optionsArray) {
    return optionsArray.map((option) => (
        <option key={option.value} value={option.value}>
            {option.label}
        </option>
    ));
}
