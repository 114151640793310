import React, { forwardRef } from "react";
import styled from "styled-components";
import Radio, { RadioProps } from "components/inputs/radio/Radio";

const Container = styled.div<ContainerStyles>`
    display: flex;
    flex-direction: ${(props) => props.direction || "row"};
    font-size: inherit;
`;

type FlexDirections = "row" | "row-reverse" | "column" | "column-reverse";

type ContainerStyles = {
    rowSeparator?: boolean;
    direction?: FlexDirections;
};

type Props = {
    options: RadioProps[];
} & ContainerStyles &
    React.ComponentPropsWithoutRef<"input">;

// https://react.dev/reference/react/forwardRef#forwarding-a-ref-through-multiple-components
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio
const RadioGroup = forwardRef<HTMLInputElement, Props>(
    ({ id, name = "groupName", onChange, onBlur, options = [], direction = "row", rowSeparator = false, ...rest }, ref) => (
        <Container id={id} direction={direction} data-testid={`test-${name}`}>
            {options.map(({ value, label, checked, disabled, withPopoverText }, index) => (
                <Radio
                    type="radio"
                    id={`${name}${index}`}
                    key={`${name}${index}`}
                    name={name}
                    value={value}
                    label={label}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    withPopoverText={withPopoverText}
                    rowSeparator={rowSeparator}
                    ref={ref}
                    {...rest}
                />
            ))}
        </Container>
    )
);

export default RadioGroup;
