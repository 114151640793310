import { createContext, useReducer, useEffect, ReactNode } from "react";
import { reducer } from "state/reducer";
import { createInitialState } from "state/createInitialState";
import { setSessionState, getSessionState } from "state/session";
import { Paths, isFinalPage } from "constants/pageNames";
import { ApplicationStates } from "state/ApplicationStateSchema";
import { ReducerActions } from "types/ActionTypes";
import { noop } from "constants/noop";

export type FormState = {
    state: ApplicationStates;
    dispatch: (formState: ReducerActions) => void;
};

export const FormStateContext = createContext<FormState>({
    state: {} as ApplicationStates,
    dispatch: noop,
});

type FormStateContextProviderProps = {
    pathname: string;
    children: ReactNode;
};

const FormStateContextProvider = ({ pathname = "", children }: FormStateContextProviderProps) => {
    // Allow persistence between refreshes for development
    const initialArg = validateState(pathname, getSessionState()) ?? {
        applicationMode: "directterm",
    };
    const [state, dispatch] = useReducer(reducer, initialArg, createInitialState);

    // Save state anytime it changes to local storage so that it can be recalled if user refreshes the page.
    useEffect(() => {
        setSessionState(state);
    }, [state]);

    return <FormStateContext.Provider value={{ state, dispatch }}>{children}</FormStateContext.Provider>;
};

export default FormStateContextProvider;

function validateState(pathname: string, state: ApplicationStates) {
    // FUTURE: Only allow matching schema for imported state
    const policyStatus = state?.application?.policyStatus;
    if (policyStatus && !isFinalPage(pathname as Paths)) {
        console.log(
            `The application state for a ${policyStatus} policy is invalid for the ${pathname} page so it will be disregarded`
        );
        return undefined;
    }
    return state;
}
