import styled from "styled-components";
import { devices } from "constants/breakpoints";

const SegmentedContainer = styled.div`
    height: 44px;
    margin: 0;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    font-weight: ${(props) => props.theme.font.weight.bold};
    background-color: ${(props) => props.theme.colors.background};
`;

const Title = styled.p`
    margin: 0;
    display: flex;
    align-items: center;

    @media ${devices.minMobile} {
        font-size: ${(props) => props.theme.font.size.h4};
    }

    @media ${devices.mobile} {
        font-size: ${(props) => props.theme.font.size.h5};
    }
`;

const SegmentedGroup = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-items: flex-start;
    border: 1px solid;
    padding: 2px;
    gap: 3px;
    border-radius: 7px;
    border-color: ${(props) => props.theme.colors.grey3};
    background-color: ${(props) => props.theme.colors.grey4};
`;

const Option = styled.div<{ selected: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    min-width: 75px;
    border-radius: 7px;
    padding: 11px;
    flex: 1;

    font-size: ${(props) => props.theme.font.size.h6};
    color: ${(props) => props.theme.colors.grey9};
    background-color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.grey4)};
`;

export type SegmentProps = {
    options: string[];
    onChange: (option: string) => void;
    value?: string;
    title: string;
};

const Segment = ({ options = [], onChange, value, title }: SegmentProps) => {
    return (
        <SegmentedContainer>
            <Title>{title}</Title>
            <SegmentedGroup>
                {options.map((option) => (
                    <Option key={option} onClick={() => onChange(option)} selected={value === option} role="columnheader">
                        {option}
                    </Option>
                ))}
            </SegmentedGroup>
        </SegmentedContainer>
    );
};

export default Segment;
