/**
 *
 * @param {string} formattedPhone
 * @returns {string}
 */
export function parseFormatted10DigitPhone(formattedPhone) {
    return formattedPhone
        .replace(/\D*/g, "")
        .replace(/^[01]*/, "")
        .substring(0, 10);
}

export function format10DigitNanpaPhone(phone) {
    if (!phone) {
        return phone;
    }
    if (phone.length !== 10) {
        console.warn(`No formatting done because of an unexpected phone length for '${phone}'`);
        return phone;
    }
    if (/\D/.test(phone)) {
        console.warn(`No formatting done because of an unexpected non-digit characters found in phone '${phone}'.`);
        return phone;
    }
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}
