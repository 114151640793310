import { MAIN_FORM_STATE_KEY } from "constants/sessionStorage";

/**
 * Saves state to local storage so that it can be recalled if user refreshes the page.
 * @param {import("./ApplicationStateSchema").ApplicationState} state
 */
export function setSessionState(state) {
    // OPTIMIZE: Either put on a delay or possibly only do when user is leaving the page
    sessionStorage.setItem(MAIN_FORM_STATE_KEY, JSON.stringify(state));
}

/**
 * Removes state from local storage so that it is not recalled after leaving the page.
 * @param {import("./ApplicationStateSchema").ApplicationState} state
 */
export function clearSessionState() {
    sessionStorage.removeItem(MAIN_FORM_STATE_KEY);
}

/**
 * Gets state from local storage so that it can be recalled if user refreshes the page.
 * @returns {import("./ApplicationStateSchema").ApplicationState} state
 */
export function getSessionState() {
    // FUTURE: Only allow matching schema for imported state
    return JSON.parse(sessionStorage.getItem(MAIN_FORM_STATE_KEY));
}
