import { ONLINE_APPLICATION_SERVICE_BASE_URL } from "env";
import { createCommonHeaders } from "./commonHeaders";
import {
    post_CheckIdentity,
    post_FindOffer,
    post_IsEligibleApplication,
    post_Submit,
    post_SubmitMLTAApplication,
} from "./online-application-service.openapi.zod";
import { z } from "zod";

type FinderNumberType = z.infer<typeof post_FindOffer.parameters.shape.body.shape.finderNumber>;
export type FindOfferResponseBody = z.infer<typeof post_FindOffer.response>;

/** Find offer details by finderNumber */
export async function findOffer(finderNumber: FinderNumberType): Promise<FindOfferResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/findOffer`, {
        method: "POST",
        body: JSON.stringify({ finderNumber: finderNumber }),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to find offer.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to findOffer.");
        }
    }
    return post_FindOffer.response.parse(await response.json());
}

type CheckIdentityRequestBody = z.infer<typeof post_CheckIdentity.parameters.shape.body>;
type CheckIdentityResponseBody = z.infer<typeof post_CheckIdentity.response>;

/** Check identity of person via the /checkIdentity endpoint */
export async function checkIdentity(person: CheckIdentityRequestBody): Promise<CheckIdentityResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/checkIdentity`, {
        method: "POST",
        body: JSON.stringify(person),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to check identity.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to checkIdentity.");
        }
    }

    return post_CheckIdentity.response.parse(await response.json());
}

type IsEligibleApplicationRequestBody = z.infer<typeof post_IsEligibleApplication.parameters.shape.body>;
type IsEligibleApplicationResponseBody = z.infer<typeof post_IsEligibleApplication.response>;

/** Check eligibility for an application using the /isEligibleApplication endpoint */
export async function isEligibleApplication(
    body: IsEligibleApplicationRequestBody
): Promise<IsEligibleApplicationResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/isEligibleApplication`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        if (response.status === 404) {
            console.warn(response);
            throw new Error("Unable to check identity.");
        } else {
            console.warn(response);
            throw new Error("Unexpected response to request to isEligibleApplication.");
        }
    }

    return post_IsEligibleApplication.response.parse(await response.json());
}

type DirectTermSubmissionRequestBody = z.infer<typeof post_Submit.parameters.shape.body>;
type DirectTermSubmissionResponseBody = z.infer<typeof post_Submit.response>;

/** Send the application via the /submit endpoint */
export async function submit(application: DirectTermSubmissionRequestBody): Promise<DirectTermSubmissionResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/submit`, {
        method: "POST",
        body: JSON.stringify(application),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        console.warn(response);
        throw new Error("Unable to submit application.");
    }

    return post_Submit.response.parse(await response.json());
}

type LoyaltySubmissionRequestBody = z.infer<typeof post_SubmitMLTAApplication.parameters.shape.body>;
type LoyaltySubmissionResponseBody = z.infer<typeof post_SubmitMLTAApplication.response>;

/** Send the application via the /submitMLTAApplication endpoint  */
export async function submitMLTAApplication(application: LoyaltySubmissionRequestBody): Promise<LoyaltySubmissionResponseBody> {
    const response = await fetch(`${ONLINE_APPLICATION_SERVICE_BASE_URL}/submitMLTAApplication`, {
        method: "POST",
        body: JSON.stringify(application),
        headers: {
            ...createCommonHeaders(window),
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        console.warn(response);
        throw new Error("Unable to submit application.");
    }

    return post_SubmitMLTAApplication.response.parse(await response.json());
}
