import { useEffect } from "react";

/**
 * A hook that sets the document.title of the page.
 * @param string
 * @returns void
 */
export function usePageTitle(title) {
    useEffect(() => {
        document.title = title;
    }, [title]);
}
