/**
 * Calculates the {@link https://www.quickquote.com/blog/how-to-calculate-your-life-insurance-age/ Age Last Birthday}
 * @param {Date} dob Date of birth
 * @param {Date} now Current date and time right now
 */
export function ageLastBirthday(dob, now) {
    var age = now.getFullYear() - dob.getFullYear();
    var monthsUntilBirthday = now.getMonth() - dob.getMonth();

    return monthsUntilBirthday < 0 || (monthsUntilBirthday === 0 && now.getDate() < dob.getDate()) ? age - 1 : age;
}

/**
 * Converts the specified date to a ISO 8601 short date string
 * @param {Date} date - The date to convert to a string value
 * @returns Returns the ISO 8601 short date string in for the specified date value in it's given time offset.
 */
export function toLocalISODateString(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return [year, month, day].join("-");
}

/**
 * Creates a Date object that reprsents the same date specified by year, month, and day
 * @param {Number} year The year value specified as a 4 digit number
 * @param {Number} month The month value between 1 and 12
 * @param {Number} day The day value between 1 and 31 depending on the month specified
 */
export function createDate(year, month, day) {
    return new Date(year, month - 1, day);
}

/**
 * Parses a short ISO 8601 date string into a Date object
 * @param {string} isoDateString
 * @returns {Date}
 */
export function isoDateStringToDate(isoDateString) {
    if (typeof isoDateString !== "string") {
        throw new Error("Invalid date string not of type string.");
    }
    if (isoDateString.length !== 10) {
        throw new Error("Invalid date string not 10 characters long.");
    }
    const match = isoDateString.match(/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/);
    if (!match?.groups) {
        throw new Error("Invalid date string not in ISO 8601 format.");
    }
    return createDate(parseInt(match.groups.year, 10), parseInt(match.groups.month, 10), parseInt(match.groups.day, 10));
}

/**
 * Formats a date in the US short date format used for display to users
 * @param {Date} date
 * @returns {string}
 */
export function formatDate(date) {
    return date.toLocaleDateString("en-us", { year: "numeric", month: "numeric", day: "numeric" });
}
