import styled from "styled-components";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, ErrorMessage } from "components/inputs";
import ScrollBox from "components/scroll-box/ScrollBox";
import { setRawHtml } from "content/setRawHtml";
import { contentReplacer } from "utils/contentReplacer";
import { logErrors } from "utils/logErrors";
import { FieldError } from "components/FieldError";
import { noop } from "constants/noop";
import { ConsentContent } from "content/contentSchemas";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.medium};
`;

const ScrollBoxInner = styled.div`
    padding-left: 1em;
    padding-right: 1em;
`;

const ConsentLabel = styled.div`
    display: flex;
`;

const FinalText = styled.div`
    margin-top: ${(props) => props.theme.spacing.padding.medium};
    margin-bottom: ${(props) => props.theme.spacing.padding.medium};
`;

const AgentStatement = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    border: ${(props) => props.theme.colors.grey7} 1px solid;
    margin-bottom: 20px;
    padding: 8px;
    align-items: center;
    gap: 10px;
    overflow: auto;
`;

const AgentStatementLabel = styled.p`
    margin: 0;
`;

const AgentSignatureImage = styled.img`
    width: 133px;
    border-bottom: black 2px solid;
`;

export type ConsentFormInitialValues = {
    agreeToConsent?: boolean;
};

export type ConsentFormFields = {
    agreeToConsent: boolean;
};

export type ConsentFormSubmittedData = { agreeToConsent: true };
export type ConsentFormSubmitCallback = (submittedData: ConsentFormSubmittedData) => void;

type ConsentFormProps = {
    firstName: string;
    initialValues: ConsentFormInitialValues;
    onSubmit: ConsentFormSubmitCallback;
    onError?: SubmitErrorHandler<ConsentFormInitialValues>;
    content: ConsentContent;
};

const ConsentForm = ({ firstName, initialValues: { agreeToConsent }, onSubmit, onError = noop, content }: ConsentFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConsentFormFields>({ defaultValues: { agreeToConsent: agreeToConsent } });

    const onErrorWithLogging: SubmitErrorHandler<ConsentFormInitialValues> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    const onSubmitWithTransformation: SubmitHandler<ConsentFormFields> = (data) => {
        onSubmit({ agreeToConsent: true });
    };

    return (
        <Container>
            <StyledForm
                id="consent-form"
                data-testid="consent-form"
                onSubmit={handleSubmit(onSubmitWithTransformation, onErrorWithLogging)}
            >
                <InputGroup>
                    <ConsentLabel>
                        <Checkbox id="agreeToConsent" direction="row" {...register("agreeToConsent", { required: true })} />
                        <div {...setRawHtml(contentReplacer({ name: firstName }, content.consentLabel))} />
                    </ConsentLabel>
                    <FieldError error={errors.agreeToConsent} />
                </InputGroup>

                {content.scrollBoxHtml && (
                    <ScrollBox>
                        <ScrollBoxInner {...setRawHtml(content.scrollBoxHtml)} />
                    </ScrollBox>
                )}

                <FinalText {...setRawHtml(content.fraudWarningHtml)} />

                <FinalText {...setRawHtml(content.agreementHtml)} />

                <FinalText {...setRawHtml(content.finalWarningHtml)} />

                {content.agentStatement && (
                    <AgentStatement>
                        <AgentStatementLabel>{content.agentStatement}</AgentStatementLabel>
                        <AgentSignatureImage src={content.agentSignatureImageUrl}></AgentSignatureImage>
                        <div {...setRawHtml(content.agentInfoHtml)} />
                    </AgentStatement>
                )}

                {Object.keys(errors).length > 0 && <ErrorMessage />}
            </StyledForm>
        </Container>
    );
};

export default ConsentForm;
