import { ComponentType, Ref, forwardRef } from "react";
import styled from "styled-components";
import ReactSelect, { ActionMeta, ControlProps, GroupBase, OnChangeValue, OptionsOrGroups, components } from "react-select";
import { FieldError } from "react-hook-form";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";

const StyledControl = styled.div<StyledDiv>`
    .react-select__control {
        box-shadow: none;
        border-width: ${(props) => (props.hasError ? "2px" : "1px")};
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

        &:hover {
            border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};
        }
    }
`;

type StyledDiv = {
    hasError?: FieldError | boolean;
};

const ControlComponent: ComponentType<ControlProps<unknown, false, GroupBase<unknown>>> = ({ ...props }) => {
    const { name } = props.selectProps;

    return (
        <StyledControl hasError={"hasError" in props.selectProps && !!props.selectProps.hasError} data-testid={`test-${name}`}>
            <components.Control {...props} />
        </StyledControl>
    );
};

// Hide the separator line beside the dropdown arrow icon
const IndicatorSeparator = () => {
    return null;
};

type AdditionalProperties = {
    isDisabled?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;

    options?: OptionsOrGroups<unknown, GroupBase<unknown>>;
    onChange?: (newValue: OnChangeValue<unknown, false>, actionMeta: ActionMeta<unknown>) => void;
};

type Props = StyledDiv & AdditionalProperties & React.ComponentPropsWithoutRef<ReactSelect>;

// https://react-select.com/components
const Select = forwardRef<StateManagedSelect, Props>(
    (
        {
            id,
            name,
            placeholder,
            onChange,
            defaultValue,
            isDisabled = false,
            isLoading = false,
            isClearable = false,
            hasError = false,
            options = [],
            ...rest
        },
        ref: Ref<any>
    ) => (
        <ReactSelect
            defaultMenuIsOpen={false}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            {...({ hasError } as any)} // So that TypeScript will allow setting unknown property
            options={options}
            components={{ Control: ControlComponent, IndicatorSeparator }}
            className="react-select-container"
            classNamePrefix="react-select"
            ref={ref}
            {...rest}
        />
    )
);

export default Select;
