import styled from "styled-components";
import { FieldError as HookFieldError } from "react-hook-form";
import { setRawHtml } from "content/setRawHtml";

const ErrorText = styled.span`
    color: red;
`;

type ValidationError = {
    error: HookFieldError | undefined;
    defaultMessage?: string;
    renderMessageAsHtml?: boolean;
};

export function FieldError({ error, defaultMessage, renderMessageAsHtml }: ValidationError) {
    if (!error) {
        return null;
    }
    const message = error.message || defaultMessage;
    let errorRefId;
    if (error.ref) {
        const ref = error.ref;
        // accept name due to react-hook-form controller not passing in element ref, but accepting a name property
        errorRefId = ("id" in ref && ref.id) || ref.name;
    }

    const [rawHtml, messageText] = renderMessageAsHtml === true ? [setRawHtml(message), undefined] : [{}, message];

    const errorId = errorRefId && `${errorRefId}__error`;
    const spanProps = {
        className: "text-error",
        role: "alert",
        id: errorId,
        "data-testid": errorId,
    };

    return (
        // Consider adding aria-describedBy on the field pointing to this error id
        <ErrorText {...spanProps} {...rawHtml}>
            {messageText}
        </ErrorText>
    );
}
