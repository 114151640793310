import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

type StyledInputProps = {
    hasError?: FieldError | boolean;
    hideSpinners?: boolean;
};

const noSpinStyle = `
&[type='number'] {
    -moz-appearance:textfield;
}

&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
`;

const StyledInput = styled.input<StyledInputProps>`
    height: 38px;
    font-size: inherit;
    padding: 0 6px;

    border-style: solid;
    border-radius: 3px;
    border-width: ${(props) => (props.hasError ? "2px" : "1px")};
    border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

    &:focus-visible {
        outline: none;
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey7)};
    }

    ${(props) => props.hideSpinners && noSpinStyle}
`;

type Props = StyledInputProps & React.ComponentPropsWithoutRef<"input">;

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number
const InputNumber = forwardRef<HTMLInputElement, Props>(
    ({ id, name, placeholder, value, onChange, disabled = false, hasError = false, hideSpinners = true, ...rest }, ref) => (
        <StyledInput
            id={id}
            name={name}
            data-testid={`test-${name}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            hasError={hasError}
            hideSpinners={hideSpinners}
            type="number"
            ref={ref}
            {...rest}
        />
    )
);

export default InputNumber;
