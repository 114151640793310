import { Genders } from "types/common/Genders";

export function toSingleLetterFromLowerCaseGender(lowerCaseGender: Genders): "M" | "F" {
    switch (lowerCaseGender) {
        case "male":
            return "M";
        case "female":
            return "F";
        default:
            throw new Error(`Unable to convert gender value ${lowerCaseGender} to "M" or "F".`);
    }
}
