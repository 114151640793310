import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SplitColumn, PageDetails, InfoPanel, ButtonGroup } from "layout";
import { H1, H2 } from "components/text";
import { Button } from "components/inputs";
import InvitationCodeForm, { OnSubmitArgs } from "features/forms/invitation-code-form/InvitationCodeForm";
import { useFormState, usePageTitle, useSendInteraction, useLandingPageUrl } from "hooks";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { TaskFallback } from "components/TaskFallback";
import { WelcomeContentSchema } from "content/contentSchemas";
import Loader from "components/loader/Loader";
import { emptyToUndefined, map, toLowerCaseGenderFromSingleLetter } from "utils/conversions";
import { findOffer } from "services/onlineApplicationService";
import { genericInvitationCodeRegex } from "constants/validations";
import { OfferFoundAction } from "types/ActionTypes";
import { assertDefined } from "utils/assertions";

const HorizontalRule = styled.hr`
    margin-top: 2em;
    margin-bottom: 1em;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TextContainer = styled.div`
    text-align: center;
    margin-bottom: 32px;
`;

const pageName = DTOPageEnum.enum.welcome;

function createOfferFoundAction(finderNumber: string, offer: Awaited<ReturnType<typeof findOffer>>): OfferFoundAction {
    return {
        type: "OFFER_FOUND",
        invitationCode: offer.finderNumber ?? finderNumber,
        firstName: map(offer.firstName, emptyToUndefined),
        lastName: map(offer.lastName, emptyToUndefined),
        planCode: assertDefined(offer.planCode),
        offer: (offer.offer ?? "").split("|"),
        keyCode: assertDefined(offer.keycode),
        aaaMemberNumber: map(offer.aaaMemberNumber, emptyToUndefined),
        memberOfferAvailable: offer.memberOfferAvailable,
        spouseOfferAvailable: offer.spouseOfferAvailable,
        membershipLength: offer.memberLoyaltyYears,
        memberSince: map(offer.memberJoinDate ?? undefined, emptyToUndefined),
        gender: toLowerCaseGenderFromSingleLetter(offer.gender) ?? undefined,
    };
}

const WelcomePage = () => {
    usePageTitle("Welcome | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);

    const { result: content, ...contentStatus } = useContent({
        targetSchema: WelcomeContentSchema,
        applicationMode: state.applicationMode,
        pageName: "welcome",
        clubCode: state.clubSpecificData?.clubCode ?? state.campaign?.clubCode ?? undefined,
    });

    const navigate = useNavigate();

    const landingPageParameters = useLandingPageUrl();
    useEffect(() => {
        dispatch({
            type: "LANDING_PAGE_VISITED",
            ...{ url: landingPageParameters.url, params: landingPageParameters.params },
        });
    }, [dispatch, landingPageParameters]);
    useEffect(() => {
        if ((state.parameterErrors?.length ?? 0) > 0) {
            console.error("Parameter errors: ", state.parameterErrors);
            navigate("/invalid-link");
            return;
        }

        if (!state.skipWelcome) {
            return;
        }

        if (!state.invitationCode) {
            return;
        }

        const finderNumber = state.invitationCode;

        findOffer(finderNumber)
            .then((offer) => {
                if (!offer.finderNumber && genericInvitationCodeRegex.test(finderNumber)) {
                    throw new Error("Unable to get individual finder number.");
                }

                dispatch(createOfferFoundAction(finderNumber, offer));

                navigate("/quote");
            })
            .catch((error) => {
                console.error(error);
                navigate("/system-error");
            });
    }, [state.invitationCode, dispatch, navigate, state.skipWelcome, state.parameterErrors]);

    const handleClick = async ({ invitationCode, offer }: OnSubmitArgs) => {
        dispatch(createOfferFoundAction(invitationCode, offer));

        if (!offer.memberOfferAvailable && !offer.spouseOfferAvailable) {
            // No offers are available
            navigate("/welcome-back");
            return;
        }

        if (state.applicationMode === "loyalty" && !("memberLoyaltyYears" in offer) && !state.memberSince) {
            console.error("Required offer properties were not provided for loyalty offer.");
            navigate("/system-error");
            return;
        }

        // FUTURE: Add any other additional offer checks for generic club links and forward
        // to system-error as needed.

        navigate("/quote");
    };

    return (
        (genericInvitationCodeRegex.test(landingPageParameters.params.get("uid") ?? "") && (
            <LoadingContainer>
                <Loader />
                <TextContainer>
                    <H1>Loading...</H1>
                </TextContainer>
            </LoadingContainer>
        )) || (
            <SplitColumn>
                {content && (
                    <TaskFallback fallback={<Loader />} errorFallback={<div>ERROR LOADING CONTENT</div>} {...contentStatus}>
                        <InfoPanel>
                            <H1>{content.leftPanelTitle}</H1>
                            <section {...setRawHtml(content.leftPanelBodyHtml)} />
                            {content.leftPanelFooterHtml && (
                                <>
                                    <HorizontalRule />
                                    <footer {...setRawHtml(content.leftPanelFooterHtml)} />
                                </>
                            )}
                        </InfoPanel>
                        <PageDetails>
                            <H2>{content.pageTitle}</H2>
                            <InvitationCodeForm
                                content={content}
                                onSubmit={handleClick}
                                invitationCode={state.application?.applicationID ?? state.invitationCode ?? undefined}
                            />
                            <ButtonGroup>
                                <Button type="submit" label={content.nextLabel} color="primary" form="invitation-code-form" />
                            </ButtonGroup>
                        </PageDetails>
                    </TaskFallback>
                )}
            </SplitColumn>
        )
    );
};

export default WelcomePage;
