import React, { forwardRef, useMemo } from "react";
import { InputText } from "components/inputs";
import { FieldError } from "react-hook-form";

type Props = {
    hasError?: FieldError;
} & React.ComponentPropsWithoutRef<"input"> & { allowedCharacterClass: string };

/**
 * Input which uses regular expression character case patterns to restricted
 * which characters are allowed to be entered.
 *
 * @example <InputTextRestricted name="zip" maxLength={5} allowedCharacterClass="0-9" />
 */
export const InputTextRestricted = forwardRef<HTMLInputElement, Props>(
    ({ allowedCharacterClass, onChange, onKeyDown, type, inputMode, hasError, ...rest }, ref) => {
        // type, inputMode are listed intentionally in order to exclude them from ...rest

        const positivePattern = useMemo(() => new RegExp(`^[${allowedCharacterClass}]$`), [allowedCharacterClass]);
        const negativePattern = useMemo(() => new RegExp(`[^${allowedCharacterClass}]`, "g"), [allowedCharacterClass]);

        return (
            <InputText
                type="text"
                inputMode="numeric"
                hasError={hasError}
                ref={ref}
                {...rest}
                onKeyDown={(e) => {
                    if (
                        ["Delete", "Backspace", "Tab", "Esc", "Enter", "Home", "End", "ArrowLeft", "ArrowRight"].includes(
                            e.key
                        ) ||
                        (e.ctrlKey &&
                            (e.key === "a" ||
                                e.key === "c" ||
                                e.key === "v" ||
                                e.key === "x" ||
                                e.key === "z" ||
                                e.key === "y")) ||
                        positivePattern.test(e.key)
                    ) {
                        if (typeof onKeyDown === "function") {
                            onKeyDown(e);
                        }
                        return;
                    }
                    e.preventDefault();
                }}
                onChange={(e) => {
                    const newValue = e.currentTarget.value.replace(negativePattern, "");
                    if (newValue !== e.currentTarget.value) {
                        e.currentTarget.value = newValue;
                    }

                    if (typeof onChange === "function") {
                        onChange(e);
                    }
                }}
            />
        );
    }
);
