import { ReactNode } from "react";

type TaskFallbackProps = {
    fallback?: JSX.Element;
    errorFallback?: JSX.Element;
    children?: ReactNode;
    hasCompleted: boolean;
    hasError: boolean;
    error?: any;
};

export function TaskFallback({
    fallback,
    errorFallback,
    children,
    hasCompleted,
    hasError,
    error,
}: TaskFallbackProps): JSX.Element | null {
    if (hasError) {
        console.error(error);
        return errorFallback ?? fallback ?? null;
    }

    if (hasCompleted) {
        return <>{children}</>;
    }

    return fallback ?? null;
}
