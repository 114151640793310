import { z } from "zod";

export function tagsAreEqual(a: any, b: any) {
    const normalizedA = normalizeTagOrder(a);
    const normalizedB = normalizeTagOrder(b);

    return JSON.stringify(normalizedA) === JSON.stringify(normalizedB);
}

export function normalizeTagOrder(tags: any) {
    if (!tags) {
        return tags;
    }
    const orderedKeys = Object.keys(tags).sort();

    const orderedTags: any = {};

    for (let key of orderedKeys) {
        const orderedValues = tags[key].sort();
        orderedTags[key] = orderedValues;
    }

    return orderedTags;
}
export function validateContent<T extends z.AnyZodObject>(schema: T, data: object): z.infer<T> {
    const result = schema.safeParse(data);
    if (result.success === false) {
        throw result.error;
    }
    return result.data;
}
