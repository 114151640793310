import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const Content = styled.div`
    display: flex;
    align-items: center;
`;

const StyledLabel = styled.p`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0 0 ${(props) => props.theme.spacing.padding.xs} 0;
`;

const StyledValue = styled.p`
    margin: 0;
`;

const EditLink = styled.a`
    font-size: ${(props) => props.theme.font.size.text};
    color: ${(props) => props.theme.colors.red};
    margin-left: ${(props) => props.theme.spacing.padding.xs};
    cursor: pointer;
    text-decoration: underline;
`;

const confirmMessageText =
    "Information you've entered on this page may be lost by performing this action. Do you wish to continue?";

const InlineTip = styled.label`
    margin-top: 8px;
    font-style: italic;
    font-size: 14px;
`;

type EditableFieldProps = {
    label?: string;
    value: string;
    onEdit?: () => void;
    tip?: string;
};

const EditableField = ({ label, value, onEdit, tip }: EditableFieldProps) => {
    const handleClickEdit = () => {
        const shouldNavigate = window.confirm(confirmMessageText);
        if (shouldNavigate && typeof onEdit === "function") {
            onEdit();
        }
    };
    return (
        <Container>
            <StyledLabel>{label}</StyledLabel>
            <Content>
                <StyledValue>{value}</StyledValue>
                {onEdit && (
                    <EditLink data-testid={label && `test-edit-link.${label.toLowerCase()}`} onClick={handleClickEdit}>
                        Edit
                    </EditLink>
                )}
            </Content>
            {tip && <InlineTip>{tip}</InlineTip>}
        </Container>
    );
};

export default EditableField;
