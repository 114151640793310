import styled from "styled-components";
import { FullPageWithBanner } from "layout/page-templates";
import { useFormCompletion, usePageTitle, useSendInteraction } from "hooks";
import { H1 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum, PagesEnumSchema } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { SessionExpiredContentSchema } from "content/contentSchemas";

const BodyContainer = styled.div`
    p {
        font-size: 18px;
    }
`;

const pageName = DTOPageEnum.enum.sessionExpired;

const SessionExpired = () => {
    usePageTitle("Session Expired | AAA Life");
    useSendInteraction(pageName);

    const [data] = useFormCompletion(pageName, (formState) => ({
        applicationMode: formState?.applicationMode,
        clubCode: formState?.clubSpecificData?.clubCode ?? formState?.campaign?.clubCode ?? undefined,
        state: formState?.application?.state,
    }));

    const { result: content } = useContent({
        targetSchema: SessionExpiredContentSchema,
        applicationMode: data.applicationMode,
        pageName: PagesEnumSchema.enum["session-expired"],
        clubCode: data.clubCode,
        state: data.state,
    });

    if (!data || !content) {
        return null;
    }

    const { title, bodyHtml } = content;

    return (
        <FullPageWithBanner>
            <H1>{title}</H1>
            <BodyContainer {...setRawHtml(bodyHtml)} />
        </FullPageWithBanner>
    );
};

export default SessionExpired;
