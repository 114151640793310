import { DTOPageEnum } from "constants/pageNames";

export const DEFAULT_PROMPT_BEFORE_IDLE = 5 * 60 * 1000;
export const DEFAULT_TIMEOUT = 10 * 60 * 1000;
export const ALLOWED_PATHNAMES = [
    "/quote",
    "/quote/results",
    "/app/personal-info",
    "/app/beneficiaries",
    "/app/secondary-addressee",
    "/app/health-statement",
    "/app/payment-info",
    "/app/review",
    "/app/consent",
];

export const pathnameToPageNameMapping = {
    "/quote": DTOPageEnum.enum.quoteStart,
    "/quote/results": DTOPageEnum.enum.quoteResults,
    "/app/personal-info": DTOPageEnum.enum.personalInfo,
    "/app/beneficiaries": DTOPageEnum.enum.beneficiary,
    "/app/secondary-addressee": DTOPageEnum.enum.secondaryAddressee,
    "/app/health-statement": DTOPageEnum.enum.healthStatement,
    "/app/payment-info": DTOPageEnum.enum.paymentInfo,
    "/app/review": DTOPageEnum.enum.review,
    "/app/consent": DTOPageEnum.enum.consent,
};
