import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { PAYMENT_GATEWAY_FRAME_BASE_URL } from "env";
import styled from "styled-components";
import { noop } from "constants/noop";

export const IFrameContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
`;

type SubmissionSucceeded = {
    event: "submissionSucceeded";
    paymentToken: string;
};
type SubmissionFailed = {
    event: "submissionFailed";
    error: any;
};
type ValidationFailed = {
    event: "validationFailed";
};
type FrameResized = {
    event: "frameResized";
    clientHeight: number;
};
export type PaymentGatewayMessages = SubmissionSucceeded | SubmissionFailed | ValidationFailed | FrameResized;
export type PaymentSubmissionCallback = ({ paymentToken }: { paymentToken: string }) => void;
export type PaymentErrorCallback = (error: any) => void;
export type PaymentValidationErrorCallback = (errorMessage: string) => void;
export type FrameResizedCallback = (clientHeight: number) => void;

export type PaymentGatewayFrameProps = React.ComponentPropsWithoutRef<"iframe"> & {
    paymentRefId: string;
    onSubmissionSucceeded?: PaymentSubmissionCallback;
    onSubmissionFailed?: PaymentErrorCallback;
    onValidationFailed?: PaymentValidationErrorCallback;
    onFrameResized?: FrameResizedCallback;
};

export type PaymentGatewayFrameElement = HTMLIFrameElement & { submit: () => void };

export const PaymentGatewayFrame = forwardRef<PaymentGatewayFrameElement, PaymentGatewayFrameProps>(
    function PaymentGatewayFrame(
        {
            paymentRefId,
            onSubmissionSucceeded = noop,
            onSubmissionFailed = noop,
            onValidationFailed = noop,
            onFrameResized = noop,
            onLoadStart = noop,
            onLoad = noop,
            ...props
        },
        ref
    ) {
        const iframeRef = useRef<PaymentGatewayFrameElement>(null);

        // Expose a custom imperative submit() function
        useImperativeHandle(
            ref,
            () => {
                return {
                    submit() {
                        if (!iframeRef.current?.contentWindow) {
                            throw new Error("The iframeRef ref was null. Unable to post submit command message.");
                        }
                        const origin = new URL(iframeRef.current.src).origin;
                        iframeRef.current.contentWindow.postMessage({ command: "submit" }, origin);
                        // We listen to the success event and only then submit
                    },
                } as unknown as PaymentGatewayFrameElement; // Trick TypeScript into beliving the objects is of type PaymentGatewayFrameElement
            },
            []
        );

        // Set up a listener for the message posted by the parent iframe element
        useEffect(() => {
            const submissionSucceeded = (paymentToken: string) => {
                onSubmissionSucceeded({ paymentToken });
            };

            const submissionFailed = (error: any) => {
                onSubmissionFailed(error);
            };

            const validationFailed = () => {
                onValidationFailed("Validation error");
            };

            const frameResized = (clientHeight: number) => {
                if (!iframeRef.current) {
                    throw new Error("iframeRef was null");
                }
                // HACK: Adjust height dynamically for inner frame
                iframeRef.current.height = (clientHeight + 2).toString();

                onFrameResized(clientHeight);
            };

            const handler = (event: { data: PaymentGatewayMessages }) => {
                if (typeof event.data !== "object" || !event.data?.event) {
                    return;
                }

                console.log("message", event.data);
                switch (event.data.event) {
                    case "submissionSucceeded": {
                        submissionSucceeded(event.data.paymentToken);
                        break;
                    }
                    case "submissionFailed": {
                        submissionFailed(event.data);
                        break;
                    }
                    case "validationFailed": {
                        validationFailed();
                        break;
                    }
                    case "frameResized": {
                        frameResized(event.data.clientHeight);
                        break;
                    }
                    default:
                        const unhandled = event.data as any;
                        console.warn(`An unexpected event ${unhandled?.event} was unhandled.`);
                        break;
                }
            };

            window.addEventListener("message", handler);

            // Cleanup by removing the event listener
            return () => window.removeEventListener("message", handler);
        }, [iframeRef, onSubmissionSucceeded, onSubmissionFailed, onValidationFailed, onFrameResized]);

        return (
            <IFrameContainer className="iframe-container" {...props}>
                <iframe
                    src={`${PAYMENT_GATEWAY_FRAME_BASE_URL}/?refid=${paymentRefId}&noauthtext=true`}
                    ref={iframeRef}
                    frameBorder="0"
                    height="700px"
                    width="100%"
                    title="payment-gateway-frame"
                    onLoadStart={onLoadStart}
                    onLoad={onLoad}
                ></iframe>
            </IFrameContainer>
        );
    }
);
