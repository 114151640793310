import styled from "styled-components";

const Container = styled.div`
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid ${(props) => props.theme.colors.grey4};
`;

const ScrollBox = ({ children }) => <Container>{children}</Container>;

export default ScrollBox;
