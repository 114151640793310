import { ButtonGroup } from "layout";
import Button from "components/inputs/button/Button";
import ChooseCoverageAmount, { QuoteResultsFormFields } from "components/choose-coverage-amount/ChooseCoverageAmount";
import { QuoteResultsContent } from "content/contentSchemas";
import { z } from "zod";
import { DirectTermApplicationStep1Schema } from "./QuoteResultsStateSchema";
import { ReplacedContent } from "utils/contentReplacer";

type DirectTermQuoteResultsContainerProps = {
    content: QuoteResultsContent;
    placeholderValues: ReplacedContent;
    applicationData: z.infer<typeof DirectTermApplicationStep1Schema>;
    onError: any;
    handleClickBack: any;
    handleClickNext: any;
};

const DirectTermQuoteResultsContainer = ({
    content,
    placeholderValues,
    applicationData,
    onError,
    handleClickBack,
    handleClickNext,
}: DirectTermQuoteResultsContainerProps) => {
    const onSubmit = async (submittedData: QuoteResultsFormFields) => {
        try {
            const selectedCoverageAmount = parseInt(submittedData.coverage, 10);
            const selectedCoveragePremium = applicationData.coverageOptions.find(
                (option) => option.coverageAmount === selectedCoverageAmount
            )?.monthlyPremium;
            const coverageAmounts = applicationData.coverageOptions.map((o) => o.coverageAmount);

            handleClickNext({
                coverageAmounts,
                coverageAmount: selectedCoverageAmount,
                coveragePremium: selectedCoveragePremium,
            });
        } catch (submissionError) {
            console.error(submissionError);
            if (onError) {
                onError(submissionError);
            }
        }
    };

    return (
        <>
            <ChooseCoverageAmount
                content={content}
                placeholderValues={placeholderValues}
                coverageAmountOptions={applicationData.coverageOptions}
                selectedCoverageAmount={applicationData.selectedCoverageAmount ?? undefined}
                onSubmit={onSubmit}
            />
            <ButtonGroup>
                <Button label={content.previousLabel} onClick={handleClickBack} />
                <Button label={content.nextLabel} type="submit" color="primary" form="quote-results-form" />
            </ButtonGroup>
        </>
    );
};

export default DirectTermQuoteResultsContainer;
