import { CDS_SERVICE_BASE_URL } from "env";
import { z } from "zod";
import { createCommonHeaders } from "./commonHeaders";

export const errorMessages = {
    invalidStateResponseBody: "The state abbreviation in the response did not consist of two upper cased letters.",
    invalidClubResponseBody: "The club Code in the response did not consist of three digits",
    zipCodeNotFound: "Unable to find state abbreviation based on ZIP code.",
    internalError: "The CDS service encountered an error or is unavailable.",
    unexpectedErrorResponse: "Unexpected response to request to find state abbreviation.",
};

/**
 * Get two letter USPS state abbreviation for a given ZIP code.
 */
export async function getStateAbbreviation(zipCode: string): Promise<string> {
    const response = await fetch(
        `${CDS_SERVICE_BASE_URL}/stateAbbreviation?` +
            new URLSearchParams({
                zipCode: zipCode,
            }),
        {
            method: "GET",
            headers: {
                ...createCommonHeaders(window),
                accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    if (response.ok) {
        // Note that even though the content type of this service specifies that
        // it should return JSON the implementation returns invalid JSON and
        // instead responds with a plain text string value. So instead of using
        // .json() below we use .text() as a workaround.
        const stateAbbreviation = await response.json(); //NOSONAR

        // Ensure response is exactly two upper case letters so that if not we get a better error logged.
        if (!/^[A-Z]{2}$/.test(stateAbbreviation)) {
            throw new Error(errorMessages.invalidStateResponseBody);
        }
        return stateAbbreviation;
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error(errorMessages.zipCodeNotFound);
    } else if (response.status === 500) {
        console.error(response);
        throw new Error(errorMessages.internalError);
    } else {
        console.error(response);
        throw new Error(errorMessages.unexpectedErrorResponse);
    }
}

const RawClubDataSchema = z.object({
    clubcode: z.string().regex(/^[0-9]{3}$/),
    companycode: z.string(),
    companyname: z.string(),
});
type RawClubData = z.infer<typeof RawClubDataSchema>;

type ClubData = {
    clubCode: string;
    companyCode: string;
    companyName: string;
};

/**
 * gets the 3 digit string of club code, 4 character company code, and the company name for a given zip code.
 */
export async function getClubData(zipCode: string): Promise<ClubData> {
    const response = await fetch(
        `${CDS_SERVICE_BASE_URL}/clubData?` +
            new URLSearchParams({
                zipCode: zipCode,
            }),
        {
            method: "GET",
            headers: {
                ...createCommonHeaders(window),
                accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    if (response.ok) {
        const clubData: RawClubData = await response.json();
        const parseResults = RawClubDataSchema.safeParse(clubData);

        if (!parseResults.success) {
            throw new Error(errorMessages.invalidClubResponseBody);
        }

        return {
            clubCode: parseResults.data.clubcode,
            companyCode: parseResults.data.companycode,
            companyName: parseResults.data.companyname,
        };
    } else if (response.status === 404) {
        console.warn(response);
        throw new Error(errorMessages.zipCodeNotFound);
    } else if (response.status === 500) {
        console.error(response);
        throw new Error(errorMessages.internalError);
    } else {
        console.error(response);
        throw new Error(errorMessages.unexpectedErrorResponse);
    }
}
