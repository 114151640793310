import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import Popover from "components/popover/Popover";

const InputSize = css<StyleProps>`
    ${(props) => {
        switch (props.radioSize) {
            case "small":
                return "20px";
            case "large":
                return "30px";
            default:
                return "24px";
        }
    }};
`;

const StyledRadio = styled.input<StyleProps>`
    margin: 0;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    accent-color: ${(props) => props.theme.colors.blue};

    height: ${InputSize};
    width: ${InputSize};
`;

const StyledLabel = styled.label<StyleProps>`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding-left: ${(props) => props.theme.spacing.padding.xs};

    color: ${(props) => {
        switch (props.disabled) {
            case true:
                return props.theme.colors.grey6;
            case false:
                return props.theme.colors.text;
            default:
                return props.theme.colors.text;
        }
    }};
`;

const RowSeparator = css`
    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.grey4}};
    }
`;

const RowWithLabel = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing.padding.xs};

    ${(props) => props.rowSeparator && RowSeparator}
`;

type StyleProps = {
    rowSeparator?: boolean;
    disabled?: boolean;
    radioSize?: "small" | "medium" | "large";
};

export type RadioProps = React.ComponentPropsWithoutRef<"input"> &
    StyleProps & {
        label: string;
        withPopoverText?: string;
    };

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio
const Radio = forwardRef<HTMLInputElement, RadioProps>(
    (
        {
            id,
            name = "Name",
            value,
            label,
            checked,
            disabled,
            onChange,
            onBlur,
            withPopoverText,
            radioSize = "medium",
            rowSeparator = false,
            ...rest
        },
        ref
    ) => (
        <RowWithLabel key={id} rowSeparator={rowSeparator}>
            <StyledRadio
                type="radio"
                id={id}
                radioSize={radioSize}
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                data-testid={`test-${name}-${value}`}
                ref={ref}
                {...rest}
            />
            <StyledLabel htmlFor={id} disabled={disabled}>
                {label}
            </StyledLabel>
            {withPopoverText && <Popover>{withPopoverText}</Popover>}
        </RowWithLabel>
    )
);

export default Radio;
