import { H3 } from "components/text";
import styled from "styled-components";
import { formatUSCurrency } from "utils/formatUSCurrency";

const OuterContainer = styled.div`
    text-align: center;
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    background-color: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.grey7};
    padding: ${(props) => props.theme.spacing.padding.small};
    font-size: ${(props) => props.theme.font.size.h5};
    font-weight: ${(props) => props.theme.font.weight.bold};
`;

// NOTE, trying to override H3 by using const Title = styled(H3) didn't seem to work, although it would be preferable
const Title = styled(H3)`
    flex-grow: 1;
    text-align: left;
`;

const CoveragePreview = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: ${(props) => props.theme.colors.background};
    padding-top: ${(props) => props.theme.spacing.padding.small};
    padding-bottom: ${(props) => props.theme.spacing.padding.small};
    padding-left: ${(props) => props.theme.spacing.padding.small};
    padding-right: ${(props) => props.theme.spacing.padding.small};
`;

const CoverageDetails = styled.span`
    padding-right: ${(props) => props.theme.spacing.padding.small};
    border-right: 1px solid ${(props) => props.theme.colors.grey4};
`;

const PaymentFigure = styled.span`
    padding-left: ${(props) => props.theme.spacing.padding.small};
`;

const PaymentFrequencyLabel = styled.span`
    font-weight: 400;
`;

/**
 * @param {("Monthly" | "Quarterly" | "Semi-Annual" | "Annual")} paymentFrequency -
 */
function toPaymentFrequencySuffix(paymentFrequency) {
    if (paymentFrequency === "Monthly") {
        return "per month";
    }
    return ""; // This case should be handled if/when we support other payment modes
}

const Premium = ({ value, paymentFrequency }) => (
    <span>
        <PaymentFigure>{formatUSCurrency(value, true)}</PaymentFigure>{" "}
        <PaymentFrequencyLabel>{toPaymentFrequencySuffix(paymentFrequency)}</PaymentFrequencyLabel>
    </span>
);
const CoverageSummary = ({ productName, coverageAmount, coverageTier, coverageType, premium, paymentFrequency }) => {
    const coverageDetails = coverageTier
        ? `${coverageTier.toUpperCase()} (${coverageType})`
        : formatUSCurrency(coverageAmount, false);

    return (
        <OuterContainer>
            <Title>{productName}</Title>
            <CoveragePreview>
                <CoverageDetails>{coverageDetails}</CoverageDetails>

                <Premium value={premium} paymentFrequency={paymentFrequency} />
            </CoveragePreview>
        </OuterContainer>
    );
};

export default CoverageSummary;
