/**
 * Returns true if phone number is 10 digits and adheres to NANPA rules for
 * phone number digits. See https://www.nationalnanpa.com/about_us/abt_nanp.html
 * for more information.
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isPhoneFormatValid(value) {
    if (!value) {
        return false;
    }

    return value.length === 10 && isValidNanpaPhone(value);
}

/**
 * Returns true if value is a valid NANPA phone number otherwise false.
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isValidNanpaPhone(value) {
    return /^([2-9][0-9]{2}){2}[0-9]{4}$/.test(value);
}
