import styled from "styled-components";
import { BeatLoader } from "react-spinners";
import React from "react";

const StyledButton = styled.button<StyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => {
        switch (props.color) {
            case "primary":
                return props.theme.colors.primary;
            case "secondary":
                return props.theme.colors.secondary;
            default:
                return props.theme.colors.grey5;
        }
    }};
    color: white;
    cursor: pointer;

    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 600;
    height: 46px;
    margin: 10px;
    width: ${(props) => {
        switch (props.size) {
            case "small":
                return "150px;";
            case "large":
                return "350px;";
            default:
                return "270px";
        }
    }};
    padding: 10px;

    &:hover {
        filter: brightness(0.95);
    }
`;

type StyleProps = {
    color?: "primary" | "secondary" | "default";
    size?: "small" | "large" | "default";
};

type ButtonProps = {
    label?: string;
    loading?: boolean;
} & StyleProps &
    React.ComponentPropsWithoutRef<"button">;

const Button = ({
    onClick = undefined,
    label = "Button",
    loading = false,
    disabled = false,
    color = "default",
    size = "default",
    ...rest
}: ButtonProps) => (
    <StyledButton onClick={onClick} disabled={disabled || loading} color={color} size={size} type="button" {...rest}>
        {loading && <BeatLoader size={18} color="white" speedMultiplier={0.8} />}
        {!loading && label}
    </StyledButton>
);

export default Button;
