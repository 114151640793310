import { useState } from "react";
import styled from "styled-components";
import { devices } from "constants/breakpoints";
import HeaderImage from "assets/images/DTO_HeaderImage.jpg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow_up.svg";

const PanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    background-color: ${(props) => props.theme.colors.grey3};

    @media ${devices.minTablet} {
        border-right: 1px solid ${(props) => props.theme.colors.grey4};
    }

    @media ${devices.tablet} {
        border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
    }
`;

const StyledImage = styled.img`
    width: 100%;
`;

const PageContent = styled.div`
    padding: 30px;

    @media ${devices.tablet} {
        padding: 14px;
        ${(props) => (props.isCollapsed ? "max-height: 65px;" : "margin-bottom: -45px;")}
    }
`;

const LargerClickArea = styled.div`
    display: none;
    rotate: ${(props) => (props.isCollapsed ? "180deg" : "initial")};
    width: 30%;
    height: 60px;
    position: relative;
    align-self: center;
    justify-content: center;
    top: 30px;

    @media ${devices.tablet} {
        display: flex;
    }
`;

const CollapseExpandButton = styled(ArrowUp)`
    display: none;
    cursor: pointer;
    background-color: white;
    fill: ${(props) => props.theme.colors.blue};
    border: 1px solid ${(props) => props.theme.colors.grey6};
    border-radius: 50%;

    width: 30%;
    height: 18px;
    width: 20px;
    padding: 4px;
    align-self: center;

    @media ${devices.tablet} {
        display: initial;
    }
`;

const InfoPanel = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const toggleCollapseExpand = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <PanelWrapper>
            <StyledImage src={HeaderImage} alt="" />
            <PageContent isCollapsed={isCollapsed}>{children}</PageContent>
            <LargerClickArea onClick={toggleCollapseExpand} isCollapsed={isCollapsed}>
                <CollapseExpandButton />
            </LargerClickArea>
        </PanelWrapper>
    );
};

export default InfoPanel;
