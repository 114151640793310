import { forwardRef } from "react";
import styled from "styled-components";

type WithDirectionProps = {
    direction: "row" | "column";
};

type WithDisabledProps = {
    disabled: boolean;
};

const Container = styled.div<WithDirectionProps>`
    display: flex;
    flex-direction: ${(props) => props.direction || "row"};
    font-size: inherit;
`;

const RowWithLabel = styled.div`
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing.padding.xs};
`;

const StyledCheckbox = styled.input`
    margin: 0;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    accent-color: ${(props) => props.theme.colors.blue};
    height: ${(props) => props.theme.spacing.padding.large};
    width: ${(props) => props.theme.spacing.padding.large};
`;

const StyledLabel = styled.label<WithDisabledProps>`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding-left: ${(props) => props.theme.spacing.padding.xs};
    padding-right: ${(props) => props.theme.spacing.padding.xs};

    color: ${(props) => {
        switch (props.disabled) {
            case true:
                return props.theme.colors.grey6;
            case false:
                return props.theme.colors.text;
            default:
                return props.theme.colors.text;
        }
    }};
`;

type CheckboxOptions = {
    value: string | ReadonlyArray<string> | number | undefined;
    label?: string;
    checked?: boolean;
    disabled?: boolean;
};

type CheckboxProps = React.ComponentPropsWithoutRef<"input"> & { options?: CheckboxOptions[] } & WithDirectionProps;

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ id, name = "radio", onChange, options = [{ value: "true" }], direction = "row", ...rest }, ref) => (
        <Container id={id} direction={direction} data-testid={`test-${name}`}>
            {options.map(({ value, label, checked, disabled }, index) => (
                <RowWithLabel key={`${name}${index}`}>
                    <StyledCheckbox
                        type="checkbox"
                        id={`${name}${index}`}
                        name={name}
                        value={value}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                        data-testid={["test", name, value ?? index].filter((s) => typeof s === "string").join("-")}
                        ref={ref}
                        {...rest}
                    />
                    <StyledLabel htmlFor={`${name}${index}`} disabled={disabled ?? false}>
                        {label}
                    </StyledLabel>
                </RowWithLabel>
            ))}
        </Container>
    )
);

export default Checkbox;
