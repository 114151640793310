import styled from "styled-components";
import { devices } from "constants/breakpoints";
import { setRawHtml } from "content/setRawHtml";

const Container = styled.div`
    max-width: 660px;
    text-align: center;
    align-self: center;
    border: 4px solid ${(props) => props.theme.colors.red};
    margin-bottom: ${(props) => props.theme.spacing.padding.medium};

    @media ${devices.minTablet} {
        padding: ${(props) => props.theme.spacing.padding.medium};
    }

    @media ${devices.tablet} {
        padding: ${(props) => props.theme.spacing.padding.small};
    }
`;

const Title = styled.p`
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.font.size.h4};
    font-weight: bold;
    margin: 0;

    padding-bottom: 14px;
`;

const Content = styled.p`
    font-size: ${(props) => props.theme.font.size.text};
    margin: auto;
    width: 75%;
`;

type Props = {
    title?: string;
    text?: string;
    bodyHtml?: string;
};

const ErrorMessage = ({ title, text, bodyHtml }: Props) => (
    <Container>
        <Title>{title || "Please review your information."}</Title>
        {(bodyHtml && <Content {...setRawHtml(bodyHtml)} />) || (
            <Content>
                {text ||
                    "There is information missing on your form. Please make the necessary corrections before continuing your application."}
            </Content>
        )}
    </Container>
);

export default ErrorMessage;
