export const colors = {
    white: "white",
    red: "#ED1C2F",
    blue: "#00529C",
    lightBlue: "#D9E8F2",
    grey9: "#262626",
    grey8: "#424242",
    grey7: "#595959",
    grey6: "#8c8c8c",
    grey5: "#bfbfbf",
    grey4: "#d9d9d9",
    grey3: "#f0f0f0",
    grey2: "#f5f5f5",
    grey1: "#fafafa",
};

export const theme = {
    colors: {
        ...colors,
        background: "white",
        text: "black",
        primary: colors.red,
        secondary: colors.blue,
    },
    spacing: {
        padding: {
            xs: "8px",
            small: "14px",
            medium: "20px",
            large: "30px",
        },
        borderRadius: {
            small: 5,
            default: 10,
        },
    },
    font: {
        family: {
            default: '"Open Sans"',
        },
        weight: {
            regular: "400",
            semibold: "600",
            bold: "800",
        },
        size: {
            h1: "60px",
            h2: "40px",
            h3: "30px",
            h4: "24px",
            h5: "18px",
            h6: "16px",
            text: "14px",
            small: "12px",
        },
    },
};
